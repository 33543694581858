<template>
  <div>
    <b-row>
      <b-col cols="6">
        <validation-provider rules="required" v-slot="{ errors }">
          <b-form-group label="Property Type">
            <b-input-group>
              <v-select
              :disabled="disabled"
                :class="errors[0] && validate ? 'border-danger-select' : ''"
                v-model="form.property_type_id"
                style="flex: 1"
                :options="optionsPropertyTypes"
                :reduce="(x) => x.id"
                label="name"
              ></v-select>
              <b-input-group-append v-if="!disabled">
                <b-button
                :disabled="disabled"
                  variant="info"
                  size="sm"
                  @click.prevent="addPropertyType"
                >
                  <feather-icon icon="PlusIcon" size="20" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col>
        <b-form-group label="County" :disabled="disabled">
          <validation-provider rules="required" v-slot="{ errors }">
            <b-form-input
              v-model="form.county"
              :class="errors[0] && validate ? 'border-danger' : ''"
            ></b-form-input>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <validation-provider rules="required" v-slot="{ errors }">
          <b-form-group label="Loan Type" :disabled="disabled">
            <v-select
            :disabled="disabled"
              :options="options"
              :class="errors[0] && validate ? 'border-danger-select' : ''"
              multiple
              v-model="form.loan_type"
            ></v-select>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  name: "MortGageLoanForm",
  props: {
    infoForm: {
      type: Object,
      required: true,
    },
    optionsPropertyTypes: {
      type: Array,
      required: true,
    },
    validate: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      options: ["CONVENTIONAL", "FHA", "VA", "USDA", "NON-QM"],
      form: {
        id: 0,
        property_type_id: "",
        county: "",
        loan_type: "",
      },
    };
  },
  watch: {
    optionsPropertyTypes(data) {
      try {
        this.form.property_type_id = data[data.length - 1].id;
      } catch (ex) {}
    },
    form: {
      handler(value) {
        this.$emit("dataForms", {
          mlf: value,
        });
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    addPropertyType() {
      try {
        this.$emit("addQuestionnaire", {
          categoryId: 9,
          title: "Add Property Type",
        });
      } catch (ex) {}
    },
  },
  mounted() {
    const { potential_appointment_forms } = this.infoForm;
    if (potential_appointment_forms) {
      this.form = potential_appointment_forms.potential_mortgage_loan;
    }
  },
};
</script>