<template>
  <div>
    <b-table
      ref="applicationsClient"
      no-border-collapse
      class="position-relative table-new-customization rounded-sm"
      :fields="visibleFields"
      show-empty
      :items="items"
      sticky-header="40vh"
      primary-key="id"
      responsive="sm"
      :busy="isBusy"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>
      <template #cell(name_user)="data">
        <span
          >{{ data.item.name_user }} <br />
          {{ data.item.created_at | myGlobal }}
        </span>
      </template>
      <template #cell(name_charge)="data">
        <span class="k-font-weight-bold"> {{ data.item.name_charge }} </span>
      </template>
      <template #cell(responsible)="data">
        <center>
          <b-badge
            id="span_status"
            :class="StatusColorBackground(data.value)"
            pill
          >
            <span v-if="data.item.responsible == 1 && moduleId == 25"
              >IN AGENT</span
            >
            <span v-else> {{ data.item.responsible }} </span>
          </b-badge>
        </center>
      </template>
      <template #cell(status)="data">
        <div v-if="typeService == 1">
          <span
            v-if="validateStatus(data.item)"
            :class="[StatusColorText(data.item)]"
            v-b-tooltip.hover
            :title="data.item.status == 0 && data.item.app_status === 'PENDING' ? 'Waiting for the advisor' : ''"
          >
             <span v-if="['DELETED', 'EXPIRED'].includes(data.item.app_status)">{{ data.item.app_status }}</span>
            <span v-else>{{ data.item.status | statusApplication }}</span>
          </span>
          <status-app
            v-if="!validateStatus(data.item)"
            :data="data"
            :options="statusOptions"
            @refreshTable="refreshTable"
          />
        </div>
        <div v-else>
          <span
            v-if="inSpecialist && currentUser.role_id !== 8"
            :class="[StatusColorText2(data.item)]"
            :title="
              data.item.status == 'PENDING' && data.item.app_status !== 'EXPIRED' ? 'Waiting for the advisor' : ''
            "
          >
            <span v-if="['DELETED', 'EXPIRED'].includes(data.item.app_status)">{{ data.item.app_status }}</span>
            <span v-else>{{ data.item.status }}</span>
          </span>
          <div v-else>
            <status-app-appointments
              v-if="typeService === 2"
              :data="data"
              :options="statusOptions"
              @addCharge="openAddCharge(data.item)"
              :service="'appointments'"
              @refreshTable="refreshTable"
            />
            <status-app-new
              v-else
              :data="data"
              :options="statusOptions"
              @addCharge="openAddCharge(data.item)"
              @refreshTable="refreshTable"
            />
          </div>
        </div>
      </template>
      <template #cell(form)="data">
        <center>
          <!-- credit cards -->
          <div v-if="data.item.status === 1 && inConnection">
            <feather-icon
              :class="[data.item.done_form == 1 ? 'active' : 'inactive']"
              icon="ClipboardIcon"
              size="20"
              @click="openPersonalInfoModal(data.item)"
            />
          </div>
          <div v-else-if="validateForm(data.item)">
            <template v-if="data.item.done_form == 1">
              <div v-b-tooltip.hover title="Completed Form">
                <b-icon
                  icon="clipboard-check"
                  scale="1.5"
                  @click="openPersonalInfoModal(data.item)"
                  variant="success"
                  class="cursor-pointer"
                />
              </div>
            </template>
            <template v-else>
              <div
                v-b-tooltip.hover
                title="Edit Form"
                @click="openPersonalInfoModal(data.item)"
                class="cursor-pointer"
              >
                <b-icon
                  icon="clipboard"
                  scale="1.5"
                  style="margin-right: 5px"
                  variant="warning"
                />
              </div>
            </template>
          </div>

          <!--potential-->
          <feather-icon
            v-if="typeService === 4"
            icon="ClipboardIcon"
            size="20"
            class="cursor-pointer"
            v-b-tooltip.hover.left="'Form Potential'"
            :class="colorForm[data.item.done_form]"
            @click="openFormModalPotencial(data.item)"
          />
          <!--appointment-->
          <template v-else-if="typeService === 2">
            <feather-icon
              v-if="data.item.status_paid == 'YES'"
              icon="ClipboardIcon"
              size="20"
              class="text-secondary text-center"
              :class="
                data.item.status_paid
                  ? data.item.app_status == 'PENDING'
                    ? 'text-warning cursor-pointer'
                    : 'text-success cursor-pointer'
                  : 'text-secondary'
              "
              @click="openFormModal(data.item)"
            />
            <feather-icon
              v-else
              icon="ClipboardIcon"
              size="20"
              class="text-secondary text-center"
            />
          </template>
        </center>
      </template>
      <template #cell(result)="data">
        <div v-if="typeService === 1 && data.item.app_status != 'EXPIRED'">
          <span
            v-if="data.item.status_result === 0 && statusApp === 3"
            class="text-uppercase text-danger"
            >Rejected</span
          >
          <status-result
            v-else-if="data.item.done_form == 1"
            :data="data"
            :options="resultAppOptions"
            @refreshTable="refreshTable"
          />
        </div>
        <div v-else>
          <ChangeStatusP
            v-if="availableForm(data.item)"
            :item="data.item"
            :moduleId="moduleId"
            :options="table.optionsStatusResult"
            :inSpecialist="inSpecialist"
            @send="updateStatus"
            nameProp="status_result"
            option="StatusResult"
            @refresh="refreshTable"
          />
        </div>
      </template>
      <template #cell(done_bank)="data">
        <div v-if="typeService == 1">
          <tabler-icon
            v-if="validateBankInfo(data.item)"
            :class="{
              'text-secondary': data.item.status_result == 0,
              'text-warning':
                data.item.status_result == 1 && data.item.done_bank == 0,
              'text-success': data.item.done_bank == 1,
            }"
            class="cursor-pointer"
            icon="BuildingBankIcon"
            size="20"
            v-b-tooltip.hover
            :title="
              data.item.done_bank == 1
                ? 'Bank info completed'
                : 'Waiting for the specialist'
            "
            @click="Boolean(data.item.status_result) && openBankInfo(data.item)"
          />
        </div>
        <div v-else>
          <tabler-icon
            v-if="availableForm(data.item) && data.item.status_result !== null"
            icon="BuildingBankIcon"
            size="20"
            :class="statusColorBankInfo(data.item)"
            @click="openBankInfoPotencial(data.item)"
          />
        </div>
      </template>
      <template #cell(paid_cc)="{ item }">
        <template v-if="item.status_paid_name === 'IN PROCESS'">
          <span class="text-warning">VERIFYING</span>
        </template>
        <template v-else>
          <ChangeStatusP
            v-if="item.status_result == 1 && item.done_bank == 1"
            :item="{ ...item, client_name: item.client_name, client_account: item.account }"
            :moduleId="moduleId"
            :options="table.optionsStatusPaid"
            :inSpecialist="!inSpecialist"
            :service="'aplications'"
            @send="updateStatusApp"
            nameProp="status_paid_name"
            option="StatusPaid"
          />
        </template>
      </template>

      <template #cell(paid_appointment)="{ item }">
        <div
          :class="{
            'text-success': item.status_paid == 'YES',
            'text-warning': item.status_paid == 'PENDING',
            'text-danger': item.status_paid == 'NO',
          }"
        >
          {{ statusPaid(item) }}
        </div>
      </template>

      <template #cell(paid_potential)="{ item }">
        <div
          v-if="item.status_paid === 'PENDING' && availableForm(item)"
          class="text-warning"
        >
          VERIFYING
        </div>
        <template v-else>
          <ChangeStatusP
            v-if="
              item.status_result == 'APPROVED' &&
              item.done_bank == 1 &&
              availableForm(item)
            "
            :item="item"
            :moduleId="moduleId"
            :options="table.optionsStatusPaid"
            :inSpecialist="inSpecialist"
            @send="updateStatus"
            nameProp="status_paid"
            option="StatusPaid"
          />
        </template>
      </template>

      <template #cell(paid_other)="{ item }">
        <div
          :class="{
            'text-success': item.status_paid == 'YES',
            'text-warning': item.status_paid == 'PENDING',
            'text-danger': item.status_paid == 'NO',
          }"
        >
          {{ statusPaid(item) }}
        </div>
      </template>

      <template #cell(amount)="data">
        <div style="text-align: right" class="pr-1" v-if="typeService == 1">
          <div
            v-if="data.item.amount != null && data.item.suggets_charge != null"
            class="d-flex flex-column"
          >
            <span title="Fee" v-b-tooltip.hover>{{
              data.item.amount | myMoney
            }}</span>
            <span
              style="margin-top: 5px"
              title="Suggested charge"
              v-b-tooltip.hover
              >{{ data.item.suggets_charge | myMoney }}</span
            >
          </div>
        </div>
        <div v-else-if="typeService == 3">
          <span>{{ data.item.amount | formatMoney }}</span>
        </div>
        <div v-else>
          <div
            v-if="data.item.amount != null && data.item.done_bank == 1"
            class="d-flex flex-column"
          >
            <span title="Fee">{{ data.item.amount | formatMoney }}</span>
          </div>
        </div>
      </template>
      <template #cell(amount_appointment)="{ item }">
        <span>$ {{ item.amount ? item.amount : "0.00" }}</span>
      </template>
      <template #cell(tracking)="data">
        <div class="d-flex justify-content-center">
          <feather-icon
            v-if="typeService == 1"
            class="cursor-pointer text-info"
            icon="ListIcon"
            size="20"
            @click="openModalTrackingApplications(data.item)"
          />
          <feather-icon
            v-else-if="typeService == 2"
            icon="ListIcon"
            size="16"
            class="cursor-pointer text-info hover-me"
            @click="openModalTrackingAppointments(data.item)"
          />
          <feather-icon
            v-else-if="typeService == 3"
            icon="ListIcon"
            size="16"
            class="cursor-pointer text-info hover-me"
            @click="openModalTrackingOtherServices(data.item)"
          />
          <feather-icon
            v-else
            class="cursor-pointer text-info"
            icon="ListIcon"
            size="20"
            @click="openModalTrackingPotential(data.item)"
          />
        </div>
      </template>
      <template #cell(potential)="{ item }">
        <template v-if="item.type_id === 145"> - </template>
        <template
          v-else-if="
            item.app_status === 'COMPLETED' && item.status_paid === 'YES'
          "
        >
          <tabler-icon
            icon="UrgentIcon"
            v-if="item.is_potencial === 'PENDING' && moduleId == 29"
            @click.prevent="addPotencial(item)"
            size="25"
            class="cursor-pointer text-warning oscillate"
          />
          <template v-else>
            <b-badge
              :variant="
                item.is_potencial === 'YES'
                  ? 'success'
                  : item.is_potencial === 'NO'
                  ? 'danger'
                  : 'warning'
              "
            >
              {{ item.is_potencial }}
            </b-badge>
          </template>
        </template>
      </template>
      <template #cell(assigned)="{ item }">
        <div class="d-flex justify-content-center">
          <div v-if="!item.name_specialist">
            <feather-icon
              v-if="
                (isCeo || isSupervisor || isChief) &&
                item.app_status == 'IN PROCESS' &&
                moduleId == 29
              "
              @click="openModalAssign(item)"
              size="16"
              icon="UserPlusIcon"
              class="mr-50 text-warning cursor-pointer"
            />
            <span
              v-else-if="item.app_status == 'IN PROCESS'"
              class="text-warning"
              >PENDING</span
            >
          </div>
          <div v-else class="d-flex justify-content-center">
            <span class="d-block">{{ item.name_specialist }} </span>
            <feather-icon
              v-if="
                (isCeo || isSupervisor || isChief) &&
                item.app_status == 'IN PROCESS' &&
                moduleId == 29
              "
              class="ml-1 text-warning cursor-pointer"
              icon="Edit2Icon"
              @click="openModalAssign(item)"
            />
          </div>
        </div>
        <span
          v-if="item.date_event"
          :class="
            colorDateAttend[item.app_status]
              ? colorDateAttend[item.app_status]
              : null
          "
        >
          {{ `${item.date_event} ${item.hour_event}` | myGlobalDay }}
        </span>
      </template>
      <template #cell(attend)="{ item }">
        <div
          class="d-flex justify-content-center align-items-center"
          style="gap: 5px"
        >
          <template v-if="item.app_status != 'COMPLETED'">
            <feather-icon
              v-if="
                item.specialist_id !== null &&
                currentUser.user_id == item.specialist_id
              "
              v-b-tooltip.hover.right="'Attend'"
              class="text-warning cursor-pointer"
              size="20"
              icon="CheckIcon"
              @click="openModalAttend(item)"
            />
            <feather-icon
              v-if="
                item.specialist_id !== null &&
                currentUser.user_id == item.specialist_id
              "
              v-b-tooltip.hover.right="'Reschedule'"
              class="text-warning cursor-pointer"
              size="20"
              icon="CalendarIcon"
              @click="openModalReschedule(item)"
            />
          </template>
          <template v-else>
            <feather-icon
              v-b-tooltip.hover.right="'Attended'"
              class="text-success"
              size="20"
              icon="CheckIcon"
            />
          </template>
        </div>
      </template>
      <template #cell(files)="data">
        <div class="position-relative">
          <feather-icon
            class="cursor-pointer"
            :class="{
              'text-secondary': data.item.status_paid != 'YES',
              'text-primary':
                data.item.status_paid == 'YES' && data.item.files_counter > 0,
            }"
            icon="PaperclipIcon"
            size="20"
            :style="{
              'pointer-events':
                data.item.status_paid == 'YES' ? 'auto' : 'none',
            }"
            @click="
              data.item.status_paid == 'YES' && openVerificationModal(data.item)
            "
          />
          <b-badge
            @click="openVerificationModal(data.item)"
            variant="primary"
            class="rounded-circle alert-files cursor-pointer"
            v-if="data.item.files_counter"
            v-b-tooltip.hover.v-primary="
              data.item.appStatus === 'IN PROCESS'
                ? 'FILES PENDING TO SEND'
                : 'FILES'
            "
          >
            {{ data.item.files_counter }}
          </b-badge>
        </div>
      </template>
      <template #cell(binnacle)="{ item }">
        <tabler-icon
          v-if="typeService == 2"
          :badge="item.total_binnacle + item.total_binnacle_files"
          badge-classes="badge-important"
          icon="BrandMessengerIcon"
          size="25"
          class="text-primary cursor-pointer"
          @click="openModalBinnacle(item)"
        />
        <tabler-icon
          v-else
          :badge="
            countMessage(
              JSON.parse(item.without_seeing),
              JSON.parse(item.without_seeing_files)
            )
          "
          badge-classes="badge-important"
          icon="BrandMessengerIcon"
          size="25"
          class="text-primary cursor-pointer"
          @click="openModalBinnacleApplication(item)"
        />
      </template>
      <template #custom-foot>
        <b-tr class="fixed-footer">
          <b-td
            :colspan="
              typeService == 2 ? indexFieldAmountAppointment : indexFieldAmount
            "
            :class="isDarkSkin ? 'bg-category-modal' : 'bg-secondary'"
          />
          <b-td
            :class="isDarkSkin ? 'bg-category-modal' : 'bg-secondary'"
            class="text-white align-middle vertical-align d-flex justify-content-end"
          >
            <span
              class="k-font-weight-bold bg-info rounded-sm text-center"
              style="padding: 3px 10px"
            >
              TOTAL
              <span class="ml-2 bg-white rounded-sm text-black px-50">
                $ {{ total }}</span
              ></span
            >
          </b-td>
          <b-td
            :colspan="typeService == 2 ? 7 : 3"
            :class="isDarkSkin ? 'bg-category-modal' : 'bg-secondary'"
          />
        </b-tr>
      </template>
    </b-table>
    <form-modal
      v-if="showForm"
      :client-data="clientData"
      :typeService="typeService"
      @closeModal="closeFormModal"
      @refreshTable="refreshTable"
      :disabled="disabled"
    />
    <personal-info-modal
      v-if="ActivePersonalInfoModal"
      :id="idApp"
      :completed="doneForm"
      :client_information="client_information"
      @refreshTable="refreshTable"
      @closeModal="closePersonalInfoModal"
    />
    <list-tracking-modal
      v-if="showTrackingApplications"
      :row="row"
      @close="closeModalTrackingApplications"
    />
    <create-tracking-modal
      v-if="ActiveCreateTrackingModal"
      :row="row"
      @close="ActiveCreateTrackingModal = false"
    />
    <bank-info-modal
      v-if="bankInfoController"
      :row="dataBank"
      @close="closeBankInfoModal"
      @refreshTable="refreshTable"
    />
    <add-charge
      v-if="showAddCharge"
      :action="1"
      :data="dataClient"
      :all-payment-methods="false"
      :send-from="sendFrom"
      @close="closeAddCharge"
      @refreshTable="refreshTable"
    />
    <add-charge-app
      v-if="showAddChargeApp"
      :action="1"
      @close="closeAddChargeApp"
      :data="dataClient"
      @refreshTable="refreshTable"
      :allPaymentMethods="false"
      :sendFrom="sendFrom"
    />
    <tracking-modal-appointment
      v-if="showTrackingAppointment"
      :client-data="clientData"
      @closeModal="closeTrackingModalAppointments"
    />
    <tracking-modal-other-services
      v-if="showTrackingServices"
      :client-data="clientData"
      @closeModal="closeTrackingModalOtherServices"
    />
    <tracking-modal-potential
      :data="showTrackingModalPotential"
      v-if="showTrackingModalPotential"
      @close="closeModalTrackingPotential"
    />
    <form-modal-potencial
      v-if="showFormPotencial"
      @closeModal="closeFormModalPotencial"
      :clientData="clientData"
      @refresh="refreshTable"
    />
    <AssignLoanExaminerModal
      v-if="showModalAssign"
      :item="selectedItem"
      @close="showModalAssign = false"
      @refresh="refreshTable"
    />
    <RescheduleAppointmentModal
      v-if="showModalReschedule"
      :item="selectedItem"
      @close="showModalReschedule = false"
      @refresh="refreshTable"
    />
    <AttendAppointment
      v-if="showModalAttend"
      :item="selectedItem"
      :redirect-completed="false"
      @close="closeModalAttend"
      @refresh="refreshTable"
    />
    <ModalBinnacleFinancialAppointment
      v-if="showModalBinnacle"
      @close="closeModalBinnacle"
      :clientInformation="client_information"
    />
    <ModalBinnacleApplications
      v-if="showModalBinnacleApplication"
      @close="showModalBinnacleApplication = false"
      :clientInformation="clientData"
      @refresh="refreshTable"
    />
    <BankInfoModalPotencial
      v-if="showBankInfoPotencial"
      @close="closeBankInfoPotencial"
      :rowData="clientData"
      @send-data="senDataBankInfo"
      :inSpecialist="inSpecialist"
    />
    <verification-modal
      v-if="ActiveVerificationModal"
      :appointment_id="String(idApp)"
      :statusService="statusService"
      :program="11"
      :type_charge="type_charge"
      @close="closeVerificationModal"
      @refresh="refreshTable"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import ListTrackingModal from "@/views/commons/components/applications/views/components/modals/ListTrackingModal";
import PersonalInfoModal from "@/views/commons/components/applications/views/components/modals/PersonalInfoModal.vue";
import BankInfoModal from "@/views/commons/components/applications/views/components/modals/BankInfoModal";
import StatusApp from "@/views/commons/components/applications/views/components/others/StatusApp.vue";
import StatusAppAppointments from "@/views/commons/components/appointments/view/component/modal/StatusApp.vue";
import StatusAppNew from "@/views/commons/components/other-services/view/component/modal/StatusAppNew.vue";
import StatusResult from "@/views/commons/components/applications/views/components/others/StatusResult.vue";
import StatusPaid from "@/views/commons/components/applications/views/components/others/StatusPaid.vue";
import CreateTrackingModal from "@/views/commons/components/applications/views/components/modals/CreateTrackingModal";
import AddCharge from "@/views/commons/components/applications/views/components/others/AddCharge.vue";
import AddChargeApp from "@/views/commons/components/other-services/view/component/modal/AddChargeModal.vue";
import StatusAppServices from "@/views/commons/components/appointments/view/component/modal/StatusApp.vue";
import FormModal from "@/views/commons/components/appointments/view/component/modal/FormModal.vue";
import TrackingModalAppointment from "@/views/commons/components/appointments/view/component/modal/TrackingModal.vue";
import TrackingModalOtherServices from "@/views/commons/components/other-services/view/component/modal/TrackingModal.vue";
import TrackingModalPotential from "@/views/commons/components/potencial-appointment/components/modals/ModalTracking.vue";
import AppointmentService from "@/views/commons/components/appointments/service/appointment.service";
import PotentialAppService from "@/views/commons/components/potencial-appointment/services/potencial-appointments.service";
import ChangeStatusP from "@/views/commons/financial-approval/ChangeStatusP.vue";
import FormModalPotencial from "@/views/commons/components/potencial-appointment/components/modals/FormModalP.vue";
import AssignLoanExaminerModal from "@/views/commons/components/appointments/view/component/modal/AssignLoanExaminer.vue";
import RescheduleAppointmentModal from "@/views/commons/components/appointments/view/component/modal/RescheduleAppointmentModal.vue";
import AttendAppointment from "@/views/commons/components/appointments/view/component/modal/AttendAppointment.vue";
import ModalBinnacleFinancialAppointment from "@/views/commons/components/appointments/view/component/modal/ModalBinnacleFinancialAppointment.vue";
import BankInfoModalPotencial from "@/views/commons/financial-approval/BankInfoModal.vue";
import VerificationModal from "@/views/commons/components/other-services/view/component/modal/VerificationModal.vue";
import ModalBinnacleApplications from "@/views/commons/components/applications/views/components/modals/ModalBinnacleApplications.vue";
import ApplicationsService from "@/views/commons/components/applications/services/applications.service.js";

export default {
  props: {
    typeService: {
      require: false,
      default: 1,
      type: Number,
    },
    /* client:{
      type:Object,
      require:
    } */
  },
  components: {
    PersonalInfoModal,
    ListTrackingModal,
    BankInfoModal,
    StatusApp,
    StatusAppNew,
    StatusResult,
    StatusPaid,
    CreateTrackingModal,
    AddCharge,
    AddChargeApp,
    StatusAppServices,
    FormModal,
    TrackingModalAppointment,
    TrackingModalOtherServices,
    ChangeStatusP,
    FormModalPotencial,
    AssignLoanExaminerModal,
    RescheduleAppointmentModal,
    AttendAppointment,
    ModalBinnacleFinancialAppointment,
    BankInfoModalPotencial,
    VerificationModal,
    ModalBinnacleApplications,
    TrackingModalPotential,
    StatusAppAppointments,
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
      getRefreshTable: "clients-store/getRefreshTable",
    }),
    inConnection() {
      return this.moduleId === 20;
    },
    visibleFields() {
      return this.fields.filter((item) => item.visible);
    },
    indexFieldAmount() {
      return this.fields
        .filter((item) => item.visible)
        .findIndex((item) => item.key == "amount");
    },
    indexFieldAmountAppointment() {
      return this.fields
        .filter((item) => item.visible)
        .findIndex((item) => item.key == "amount_appointment");
    },
    moduleId() {
      return this.$route.matched[0]?.meta?.module;
    },
    inSpecialist() {
      const modules = [11, 25, 28, 29, 30];
      return modules.includes(this.moduleId);
    },
    isCeo() {
      return this.currentUser.user_id === 1;
    },
    isSupervisor() {
      return this.currentUser.role_id === 2;
    },
    isChief() {
      return this.currentUser.role_id === 17;
    },
  },
  data() {
    return {
      trackingController: false,
      bankInfoController: false,
      fields: [
        {
          key: "name_user",
          label: "Created by",
          visible: true,
        },
        {
          key: "name_charge",
          label: "Type",
          visible: true,
        },
        {
          key: "responsible",
          label: "Responsible",
          thClass: "text-center",
          tdClass: "text-center",
          visible: true,
        },
        {
          key: "status",
          label: "Status",
          thClass: "text-center",
          tdClass: "text-center",
          visible: true,
        },
        {
          key: "paid_appointment",
          label: "Paid",
          thClass: "text-center",
          tdClass: "text-center",
          visible: true,
        },
        {
          key: "amount_appointment",
          label: "Amount",
          thClass: "text-right",
          tdClass: "text-right",
          visible: true,
        },
        {
          key: "form",
          label: "Form",
          thClass: "text-center",
          tdClass: "text-center",
          visible: true,
        },
        {
          key: "result",
          label: "Result",
          thClass: "text-center",
          tdClass: "text-center",
          visible: true,
        },
        {
          key: "done_bank",
          label: "Bank info",
          thClass: "text-center",
          tdClass: "text-center",
          visible: true,
        },
        {
          key: "paid_cc",
          label: "Paid",
          thClass: "text-center",
          tdClass: "text-center",
          visible: true,
        },
        {
          key: "paid_other",
          label: "Paid",
          thClass: "text-center",
          tdClass: "text-center",
          visible: true,
        },
        {
          key: "paid_potential",
          label: "Paid",
          thClass: "text-center",
          tdClass: "text-center",
          visible: true,
        },
        {
          key: "assigned",
          label: "assigned to",
          thClass: "text-center",
          tdClass: "text-center",
          visible: false,
        },
        {
          key: "attend",
          label: "Attend",
          thClass: "text-center",
          tdClass: "text-center",
          visible: false,
        },
        {
          key: "potential",
          label: "potential",
          thClass: "text-center",
          tdClass: "text-center",
          visible: false,
        },
        {
          key: "amount",
          label: "Amount",
          thClass: "text-right",
          tdClass: "text-right",
          visible: true,
        },
        {
          key: "binnacle",
          label: "binnacle",
          thClass: "text-center",
          tdClass: "text-center",
          visible: false,
        },
        {
          key: "files",
          label: "File",
          class: "text-center",
          visible: true,
        },
        {
          key: "tracking",
          label: "Tracking",
          thStyle: {
            textAlign: "center",
          },
          visible: true,
        },
      ],
      trackingData: [],
      items: [],
      isBusy: false,
      total: 0,
      dataBank: [],
      idApp: "",
      statusOptions: [
        { value: null, text: "" },
        { value: 1, text: "ACCEPTED" },
        { value: 2, text: "REJECTED" },
      ],
      resultOptions: [
        { value: null, text: "" },
        { value: 0, text: "PENDING" },
        { value: 1, text: "ACCEPTED" },
        { value: 2, text: "DENIED" },
      ],
      resultAppOptions: [
        { value: null, text: "" },
        { value: 3, text: "IN PROCESS" },
        { value: 1, text: "APPROVED" },
        { value: 2, text: "DENIED" },
      ],
      paidOptions: [
        { value: null, text: "" },
        { value: 1, text: "YES" },
        { value: 2, text: "NO" },
      ],
      ActivePersonalInfoModal: false,
      ActiveBankInfoModal: false,
      ActiveVerificationModal: false,
      ActiveListTrackingModal: false,
      ActiveCreateTrackingModal: false,
      row: {},
      showAddCharge: false,
      showAddChargeApp: false,
      dataClient: {},
      sendFrom: "",
      showForm: false,
      clientData: {},
      disabled: false,
      showTrackingApplications: false,
      showTrackingAppointment: false,
      showTrackingServices: false,
      table: {
        optionsStatusResult: ["IN PROCESS","APPROVED", "DENIED"],
        optionsStatusPaid: ["", "YES", "NO"],
      },
      colorForm: {
        SAVE: "text-warning",
        SEND: "text-success",
      },
      showFormPotencial: false,
      selectedItem: {},
      showModalAssign: false,
      showModalReschedule: false,
      showModalAttend: false,
      colorDateAttend: {
        PENDING: "text-warning",
        "IN PROCESS": "text-warning",
        RESCHEDULED: "text-info",
        COMPLETED: "text-success",
      },
      showModalBinnacle: false,
      client_information: {},
      showBankInfoPotencial: false,
      optionsStatusPaidPotential: ["", "YES", "NO"],
      type_charge: null,
      showModalBinnacleApplication: false,
      showTrackingModalPotential: false,
    };
  },
  async created() {
    this.sendFrom = {
      1: "app_main",
      2: "appointments_financial",
      3: "other_services_financial",
      4: "potencial_appointments",
    }[this.typeService];
    this.onControl = true;
    this.filesByTypeService();
    await this.getApplicationsData();
  },
  watch: {
    getRefreshTable(value) {
      if (value) {
        this.refreshTable();
        this.CHANGE_REFRESH_TABLE(false);
      }
    },
  },
  methods: {
    countMessage(messages, messageFile) {
      let mess = messages || [];
      let file = messageFile || [];
      let joinMessage = mess.concat(file);
      if (
        joinMessage.length > 0 &&
        joinMessage[0].user_id !== this.currentUser.user_id
      ) {
        return joinMessage.length;
      }
    },
    openModalBinnacleApplication(item) {
      this.showModalBinnacleApplication = true;
      this.clientData = {
        name_client: item.client_name,
        module: item.module,
        created_at: item.created_at,
        name_advisor: item.name_advisor,
        name_charge: item.full_charge,
        id: item.app_id,
        section: "application",
        recommended_by: item.name_user,
      };
    },
    ...mapActions({
      CHANGE_REFRESH_TABLE: "clients-store/CHANGE_REFRESH_TABLE",
      A_GET_ALL_SERVICES_COUNTER: "clients-store/A_GET_ALL_SERVICES_COUNTER",
    }),
    validateBankInfo(data) {
      return (
        (data.status === 1 &&
          data.status_result == 1 &&
          this.inSpecialist &&
          this.currentUser.role_id !== 8) ||
        (data.status === 1 &&
          data.status_result == 1 &&
          (!this.inSpecialist || this.currentUser.role_id === 8))
      );
    },
    openVerificationModal({ id, app_status, type_id }) {
      this.idApp = id;
      this.statusService = app_status == "IN PROCESS" ? 1 : 2;
      this.ActiveVerificationModal = true;
      this.type_charge = type_id;
    },
    closeVerificationModal() {
      this.ActiveVerificationModal = false;
    },
    validateStatus(data) {
      return (
        data.program_id != 7 &&
        this.inSpecialist &&
        this.currentUser.role_id !== 8
      );
    },
    validateForm(data) {
      return (
        (data.status === 1 &&
          this.inSpecialist &&
          this.currentUser.role_id !== 8) ||
        (data.status === 1 &&
          (!this.inSpecialist || this.currentUser.role_id === 8))
      );
    },
    StatusColorText({ status, app_status }) {
      if(app_status === 'DELETED' || app_status === 'EXPIRED') return "text-danger";

      switch (status+1) {
        case 1:
          return "text-warning";
        case 2:
          return "text-success";
        case 3:
          return "text-danger";
        default:
          return "bg-secondary text-light";
      }
    },
    StatusColorText2({ app_status, status }) {
      if(app_status === "DELETED" || app_status === "EXPIRED") return "text-danger";

      switch (status) {
        case "PENDING":
          return "text-warning";
        case "ACCEPTED":
          return "text-success";
        case "REJECTED":
          return "text-danger";
        default:
          return "bg-secondary text-light";
      }
    },
    StatusColorBackground(status) {
      switch (status) {
        case "IN ADVISOR":
          return ["bg-light-warning"];
        case "IN SPECIALIST":
          return ["bg-light-primary"];
        case 3:
          return ["bg-light-success"];
        default:
          return "bg-secondary text-light";
      }
    },
    filesByTypeService() {
      this.fields.forEach((element) => {
        if (element.key === "done_bank") {
          element.visible = [1, 4].includes(this.typeService);
        }
        if (element.key === "form") {
          element.visible = this.typeService !== 3;
        }
        if (element.key === "result") {
          element.visible = ![2, 3].includes(this.typeService);
        }

        if (element.key == "potential") {
          element.visible = this.typeService === 2;
        }
        if (element.key === "status") {
          element.visible = this.typeService !== 4;
        }
        if (element.key === "assigned") {
          element.visible = this.typeService === 2;
        }
        if (element.key === "binnacle") {
          element.visible = this.typeService === 2 || this.typeService == 1;
        }
        if (element.key === "paid_cc") {
          element.visible = this.typeService == 1;
        }
        if (element.key === "paid_appointment") {
          element.visible = this.typeService === 2;
        }
        if (element.key === "paid_other") {
          element.visible = this.typeService == 3;
        }
        if (element.key === "paid_potential") {
          element.visible = this.typeService == 4;
        }
        if (element.key === "amount") {
          element.visible = this.typeService !== 2;
        }
        if (element.key === "amount_appointment") {
          element.visible = this.typeService === 2;
        }
        if (element.key === "attend") {
          element.visible = this.typeService === 2;
        }
        if (element.key === "files") {
          element.visible = this.typeService === 3;
        }
      });
    },
    openPersonalInfoModal(data) {
      data.done_form ? (this.doneForm = true) : (this.doneForm = false);
      this.idApp = data.app_id;
      this.client_information = data;
      this.ActivePersonalInfoModal = true;
    },
    async openBankInfo(item) {
      this.dataBank = item;
      this.bankInfoController = true;
    },
    async senDataBankInfo(value) {
      try {
        this.addPreloader();
        const data = await PotentialAppService.updateDataBank(value);

        this.refreshTable();
        this.closeBankInfoPotencial();
      } catch (ex) {
        console.error(ex);
      } finally {
        this.removePreloader();
      }
    },
    async openModalTracking(item) {
      this.idApp = item;
      this.trackingController = true;
    },
    async openModalTrackingPotential(item) {
      item.account = item.client_account;
      this.showTrackingModalPotential = item;
    },
    async closeModalTrackingPotential() {
      this.showTrackingModalPotential = null;
    },
    async itemsApp() {
      await this.getApplicationsData();
      return this.items;
    },
    async getApplicationsData() {
      this.addPreloader();
      try {
        this.isBusy = true;
        const data = await ClientsOptionsServices.searchClientApplications({
          id: this.client.id,
          typeService: this.typeService,
          user_id: this.currentUser.user_id,
        });
        if (data.status === 200) {
          this.items = data.data;
          // eslint-disable-next-line array-callback-return
          if (this.items.length !== 0) {
            this.items.map((dato) => {
              this.total = Number(dato.total).toFixed(2);
            });
          }
          this.isBusy = false;

          // return this.items
        }
      } catch (e) {
        this.isBusy = false;
        this.showErrorSwal(e);
      } finally {
        this.removePreloader();
      }
    },
    async refreshTable() {
      this.$refs.applicationsClient.refresh();
      await this.A_GET_ALL_SERVICES_COUNTER({
        client_account_id: this.client.id,
        module_id: this.moduleId
      });
      await this.getApplicationsData();
    },
    async closePersonalInfoModal() {
      await this.getApplicationsData();
      this.ActivePersonalInfoModal = false;
    },
    closeBankInfoModal() {
      this.bankInfoController = false;
    },
    openCreateTrackingModal(row) {
      this.row = row;
      this.row.account = this.client.account;
      this.row.name_client = this.client.client_name;
      this.ActiveCreateTrackingModal = true;
    },
    openAddCharge(data) {
      this.dataClient = data;
      this.showAddCharge = true;
    },
    openAddChargeApp(data) {
      this.dataClient = data;
      this.dataClient.charge_description = data.name_charge;
      this.showAddChargeApp = true;
    },
    closeAddCharge() {
      this.showAddCharge = false;
    },
    closeAddChargeApp() {
      this.showAddChargeApp = false;
    },
    openFormModal(data) {
      this.showForm = true;
      this.clientData = data;
      this.disabled = data.app_status != 'PENDING';
    },
    closeFormModal() {
      this.showForm = false;
    },
    openModalTrackingApplications(row) {
      row.name_client = row.client_name;
      this.row = row;
      this.showTrackingApplications = true;
    },
    closeModalTrackingApplications() {
      this.showTrackingApplications = false;
    },
    openModalTrackingAppointments(data) {
      this.showTrackingAppointment = true;
      this.clientData = data;
    },
    closeTrackingModalAppointments() {
      this.showTrackingAppointment = false;
    },
    openModalTrackingOtherServices(data) {
      this.showTrackingServices = true;
      this.clientData = data;
    },
    closeTrackingModalOtherServices() {
      this.showTrackingServices = false;
    },
    async addPotencial(item) {
      try {
        let confirmed = "NO";
        const valid = await this.showQuestionSwal("Is this client potential?");
        if (!valid.dismiss || valid.dismiss == "cancel") {
          if (valid.isConfirmed) {
            confirmed = "YES";
          }
          this.addPreloader();
          const params = {
            id: item.id,
            confirmed,
            module_id: this.moduleId,
            user_id: this.currentUser.user_id,
          };

          const data = await AppointmentService.updateReprogrammingAppointment(
            params
          );
          if (confirmed == "NO") {
            this.refreshTable();
            this.showSuccessSwal("The client has been marked as not potential");
          } else {
            this.refreshTable();
            this.showSuccessSwal("The client has been marked as potential");
          }
          await this.A_GET_PENDING_POTENTIAL();
        }
      } catch (ex) {
        console.log(ex);
      } finally {
        this.removePreloader();
      }
    },
    availableForm(item) {
      return item.done_form ? item.done_form === "SEND" : false;
    },
    statusPaid(item) {
      return item.status_paid == "PENDING" ? "VERIFYING" : item.status_paid;
    },
    async updateStatus(data) {
      let _data = {
        n_potencial_appointment_id: data.n_id,
        n_module_id: data.n_module_id,
        n_user_id: data.n_user_id,
        n_action: data.n_action,
        n_option: data.n_option,
        n_comment: data.n_comment,
      };
      try {
        this.addPreloader();
        const { n_option, n_action } = data;
        if (n_option === "StatusPaid") {
          if (n_action === "NO" || n_action === "PENDING") {
            await PotentialAppService.updateStatus(_data);
            this.refreshTable();
          } else {
            this.openAddCharge(data);
          }
        } else {
          await PotentialAppService.updateStatus(_data);
          this.refreshTable();
        }
      } catch (ex) {
        this.showErrorSwal(ex);
      } finally {
        this.removePreloader();
      }
    },
    async updateStatusApp(data) {
      let _data = {
        id_app: data.n_app_id,
        modul: data.n_module_id,
        userId: data.n_user_id,
        n_action: data.n_action,
        n_option: data.n_option,
        t_comment: data.n_comment,
      }

      try {
        this.addPreloader();
        const { n_option, n_action } = data;
        if (n_option === "StatusPaid") {
          if (n_action === "NO" || n_action === "PENDING") {
            await ApplicationsService.updateStatusApplication(_data);
            this.refreshTable();
          } else {
            this.openAddChargeApp(data);
          }
        } else {
          await ApplicationsService.updateStatusApp(_data);
          this.refreshTable();
        }
      } catch (ex) {
        this.showErrorSwal(ex);
      } finally {
        this.removePreloader();
      }
    },
    async openFormModalPotencial(item) {
      try {
        let paf = item.done_form;
        this.showFormPotencial = true;
        this.clientData = {
          id: item.id,
          account_id: item.account_id,
          status_done: paf ? paf.done_form : "SAVE",
        };
      } catch (ex) {
        console.log(ex);
      }
    },
    closeFormModalPotencial() {
      this.showFormPotencial = false;
      this.clientData = {};
    },
    openModalAssign(item) {
      this.selectedItem = item;
      this.showModalAssign = true;
    },
    async removeRegister(item) {
      const params = {
        id: item.id,
        user_id: this.currentUser.user_id,
      };
      try {
        const confirm = await this.showConfirmSwal();
        if (!confirm.isConfirmed) return;

        this.addPreloader();
        const data = await AppointmentService.removeRegister(params);
        if (data.success) {
          this.showToast(
            "success",
            "top-right",
            "Success",
            "CheckIcon",
            "Removed successfully"
          );
          this.refreshTable();
        } else {
          this.showToast(
            "danger",
            "top-right",
            "Error",
            "CXIcon",
            "Failed process"
          );
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    openModalAttend(item) {
      this.selectedItem = item;
      this.showModalAttend = true;
    },
    closeModalAttend() {
      this.showModalAttend = false;
    },
    openModalReschedule(item) {
      this.selectedItem = item;
      this.showModalReschedule = true;
    },
    openModalBinnacle(data) {
      this.showModalBinnacle = true;
      this.client_information = data;
      this.client_information.recommended_by = data.name_user;
      this.client_information.name_client = data.client_name;
      this.client_information.name_charge = data.type_description;
    },
    closeModalBinnacle() {
      this.showModalBinnacle = false;
      this.clientData = null;
      this.refreshTable();
    },
    statusColorBankInfo(item) {
      let color;
      if (item.done_bank) {
        let status_result = item.status_result ?? this.validStatusResult(item);
        switch (status_result) {
          case "PENDING":
            color = "text-warning";
            break;
          case "APPROVED":
            color = "text-success cursor-pointer";
            break;
          case "DENIED":
            color = "text-danger";
            break;
          case "PENDING-BANK":
            color = "text-warning cursor-pointer";
            break;
          default:
            color = "text-secondary";
            break;
        }
      } else if (item.status_result === "DENIED") {
        color = "text-secondary";
      } else {
        color = "text-warning cursor-pointer";
      }
      return color;
      // item.status_result === 'APPROVED' ? 'text-success' : 'text-secondary'
    },
    validStatusResult(item) {
      return item.status_result === "PENDING" ||
        item.status_result === "DENIED" ||
        item.status_result == null
        ? "DEFAULT"
        : "PENDING-BANK";
    },
    async openBankInfoPotencial(item) {
      try {
        if (item.status_result === "APPROVED") {
          this.addPreloader();
          const data = await PotentialAppService.getDataBank({
            n_pa_id: item.id,
          });
          this.showBankInfoPotencial = true;
          this.clientData = data;
          this.removePreloader();
        }
      } catch (ex) {
        this.removePreloader();
      }
    },
    closeBankInfoPotencial() {
      this.showBankInfoPotencial = false;
    },
  },
};
</script>
<style>
</style>
<style scoped>
.active {
  color: var(--success);
  cursor: pointer;
}
.inactive {
  color: var(--secondary);
  cursor: pointer;
}
.fixed-footer {
  position: sticky;
  bottom: 0;
  background-color: rgba(212, 201, 201, 0.164);
  border-top: 1px solid #ddd;
  padding: 5px 0px;
}
.oscillate {
  animation-name: oscillate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
.alert-files {
  position: absolute;
  top: -10px;
}
@keyframes oscillate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
</style>
