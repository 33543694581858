<template>
  <b-modal
    ref="modalName"
    modal-class="modal-primary"
    title-class="h3 text-white"
    :title="`RESCHEDULE ${item.type_description.toUpperCase()}`"
    size="lg"
    @hidden="closeModal()"
    centered
    no-close-on-backdrop
  >
    <div>
      <validation-observer ref="formReschedule">
        <b-row>
          <b-col lg="12" xl="6">
            <validation-provider rules="required" v-slot="{ errors }">
              <b-form-group label="DAY: ">
                <b-form-datepicker
                  v-model="inputDate"
                  class="form-control"
                  :class="errors[0] && validate ? 'border-danger' : ''"
                  :min="new Date()"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  @input="changeDate"
                />
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col lg="12" xl="6">
            <validation-provider rules="required" v-slot="{ errors }">
              <b-form-group label="HOUR: ">
                <b-form-select
                  v-model="inputTime"
                  :options="timeOptions"
                  class="form-control"
                  :class="errors[0] && validate ? 'border-danger' : ''"
                  :disabled="inputDate == null"
                />
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Motive (optional):">
              <b-form-textarea
                id="message"
                v-model="comment"
                rows="2"
                maxlength="1000"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </div>
    <template #modal-footer>
      <b-button
        class="d-flex justify-content-center"
        variant="primary"
        @click="rescheduleAppointment()"
      >
        SAVE
      </b-button>
    </template>
  </b-modal>
</template>
<script>
// SERVICES
import SettingsServiceSp from "@/views/specialists/sub-modules/financial-approval/views/settings/service/settings.service.js";
import FinancialApprovalService from "@/views/specialists/sub-modules/financial-approval/services/financial-approval.service";
import AppointmentService from "@/views/commons/components/appointments/service/appointment.service";

// STORE
import { mapGetters } from "vuex";

export default {
  props: {
    item: Object,
  },
  data() {
    return {
      inputDate: null,
      inputTime: null,
      timeOptions: [],
      schedule: [],
      validate: false,
      comment: null,
    };
  },
  mounted() {
    this.toggleModal("modalName");
  },
  async created() {
    await this.getSchedule();
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    async getSchedule() {
      const { data } = await SettingsServiceSp.getAppointmentSchedule();
      this.schedule = data;
    },
    async rescheduleAppointment() {
      try {
        this.validate = true;
        const isValid = await this.$refs.formReschedule.validate();
        if (!isValid) {
          return;
        }
        this.addPreloader();
        let result = await FinancialApprovalService.storeDate({
          form_appointment_id: this.item.form_appointment_id,
          appointment_date: this.inputDate,
          appointment_time: this.inputTime,
        });
        await this.updateAppointmentStatus("reschedule");
        if (result) {
          this.showSuccessSwal();
          this.$emit("refresh");
        }
      } catch (error) {
        this.showInfoSwal("The picked date is the same as the current one");
      } finally {
        this.closeModal();
        this.removePreloader();
      }
    },
    async updateAppointmentStatus(status) {
      const { data } = await AppointmentService.updateAppointmentStatus({
        type: "app_status",
        appointment_id: this.item.id,
        button_selected: status,
        created_by: this.currentUser.user_id,
        motive: this.comment,
        type_charge: this.item.type_id,
      });
      return data;
    },
    changeDate(value) {
      this.timeOptions = [];
      this.inputTime = null;
      const selectedDate = new Date(value);
      const dayOfWeek = selectedDate.getDay();
      const { start_hour, end_hour } = this.schedule.find(
        (item) => item.day == dayOfWeek + 1
      );
      this.timeOptions = this.generateTimeOptions(start_hour, end_hour, 60);
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>