<template>
  <div>
    <b-row>
      <b-col>
        <validation-provider rules="required" v-slot="{ errors }">
          <b-form-group label="Make" :disabled="disabled">
            <b-form-input
              v-model="form.make"
              :class="errors[0] && validate ? 'border-danger' : ''"
            ></b-form-input>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col>
        <validation-provider rules="required" v-slot="{ errors }">
          <b-form-group label="Model" :disabled="disabled">
            <b-form-input
              v-model="form.model"
              :class="errors[0] && validate ? 'border-danger' : ''"
            ></b-form-input>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col>
        <b-form-group label="Current market value" :disabled="disabled">
          <money
            class="form-control"
            v-bind="vMoney"
            v-model="form.current_market_value"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="8">
        <validation-provider rules="required" v-slot="{ errors }">
          <b-form-group label="Loan purpose">
            <v-select
              :disabled="disabled"
              :class="errors[0] && validate ? 'border-danger-select' : ''"
              v-model="form.loan_purpose"
              :searchable="false"
              :options="options"
              :reduce="(x) => x.value"
              label="text"
            ></v-select>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col>
        <b-form-group
          label="Vehicle Identification Number"
          :disabled="disabled"
        >
          <b-form-input
            v-model="form.vehicle_identification_number"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { VMoney } from "v-money";

export default {
  name: "AutLoanForm",
  props: {
    infoForm: {
      type: Object,
      required: true,
    },
    validate: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  directives: { money: VMoney },
  data() {
    return {
      vMoney: {
        decimal: ".",
        thousand: ",",
        prefix: "$ ",
        precision: 2,
        min: 0.0,
      },
      options: [
        {
          text: "Lower interest rate",
          value: "1",
        },
        {
          text: "Reduce monthly payment",
          value: "2",
        },
        {
          text: "Transfer credit",
          value: "3",
        },
      ],
      form: {
        id: 0,
        make: "",
        model: "",
        current_market_value: 0,
        loan_purpose: "",
        vehicle_identification_number: "",
      },
    };
  },
  watch: {
    form: {
      handler(value) {
        this.$emit("dataForms", {
          alf: value,
        });
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    const { potential_appointment_forms } = this.infoForm;
    if (potential_appointment_forms) {
      this.form = potential_appointment_forms.potential_auto_loan;
    }
  },
};
</script>