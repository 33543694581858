<template>
  <BinnacleModal
    @close="closeModal"
    v-if="showModalBinnacle"
    @newMessageBinnacle="newMessageBinnacle"
    @newFileBinnacle="newFileBinnacle"
    @markMessageAsViewed="markMessageAsViewed"
  />
</template>
<script>
import BinnacleModal from "@/views/commons/binnacle/BinnacleModal.vue";
import BinnacleFinancialAppointmentService from "@/views/commons/components/appointments/service/binnacle-financial-appointment.service.js";
import { BinnacleService } from "@/views/commons/binnacle/service/binnacle.service";

import { mapActions, mapGetters } from "vuex";
export default {
  name: "ModalBinnacleFinancialAppointment",
  props: {
    clientInformation: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      showModalBinnacle: false,
      binnacle: new BinnacleService(BinnacleFinancialAppointmentService),
    };
  },
  components: {
    BinnacleModal,
  },
  computed: {},
  watch: {},
  methods: {
    ...mapActions({
      addClientInformation: "BinnacleStore/addClientInformation",
    }),
    async openModalBinnacle() {
      try {
        this.addPreloader();

        await this.binnacle.getComments({ id: this.clientInformation.id });

        await this.binnacle.getMessageStatus({
          appointment_id: this.clientInformation.id,
        });

        this.addClientInformation(this.clientInformation);

        this.removePreloader();

        this.showModalBinnacle = true;
      } catch (ex) {
        this.showModalBinnacle = false;
        this.removePreloader();
        this.closeModal();
      }
    },
    closeModal() {
      this.$emit("close");
    },
    async newMessageBinnacle(value) {
      await this.binnacle.insertMessage(value);
    },
    async newFileBinnacle(value) {
      const { id, user_id, files, type } = value;
      const formData = new FormData();
      formData.append("_id", id);
      formData.append("_user_id", user_id);
      formData.append("_type", type);
      files.forEach((file) => {
        formData.append("files[]", file);
      });
      await this.binnacle.insertFiles(formData);
    },
    async markMessageAsViewed(value) {
      await BinnacleFinancialAppointmentService.markAsSeen(value);
    },
  },
  async mounted() {
    await this.openModalBinnacle();
  },
};
</script>
