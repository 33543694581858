class PotencialAppointmentFormService {
    constructor() {
        this.url = '/potential-appointment/forms';
    }
    async getForm(params) {
        const { data } = await amgApi.post(`${this.url}/show`, params);
        return data;
    }
    async store(params) {
        const { data } = await amgApi.post(`${this.url}/store`, params,{
            headers: {
                'Content-Type':'multipart/form-data'
            }
        });
        return data;
    }
    async getQuestionnaireItems(params) {
        const { data } = await amgApi.post(`${this.url}/get-questionnaire-items`, params);
        return data;
    }
    async deleteFilesForm(params){
        const { data } = await amgApi.post(`${this.url}/delete-files`, params);
        return data;
    }
}
export default new PotencialAppointmentFormService();