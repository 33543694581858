<template>
  <b-modal
    v-model="showModal"
    size="sm"
    :title="titleModal"
    title-class="h5 text-white font-weight-bolder"
    @hide="closeModal"
    no-close-on-backdrop
    centered
  >
    <ValidationObserver ref="observerCreated">
      <b-row>
        <b-col cols="12">
          <label for="input-live">NAME:</label>
          <ValidationProvider name="name" rules="required" v-slot="{ errors }">
            <b-form-input
              id="input-live"
              v-model="name"
              autofocus
              :class="errors[0] == 'is required' && 'border-danger rounded'"
            />
          </ValidationProvider>
        </b-col>
      </b-row>
    </ValidationObserver>
    <template #modal-footer>
      <b-button variant="primary" active:isActive @click="save">
        Save
      </b-button>
      <b-button variant="secondary" @click="closeModal"> Cancel </b-button>
    </template>
  </b-modal>
</template>
<script>
import ncrTypeService from "@/views/specialists/sub-modules/financial-approval/views/settings/service/settings.service.js";

export default {
  name: "CreateQuestionnaireModal",
  props: {
    categoryId: {
      type: Number,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
    titleModal: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      name: "",
      showModal: false,
    };
  },
  methods: {
    async save() {
      try {
        let isValid = await this.$refs["observerCreated"].validate();
        if (isValid) {
          const confirm = await this.showConfirmSwal("Are you sure?", null);
          if (!confirm.value) {
            return;
          }
          this.addPreloader();
          const { data } = await ncrTypeService.saveQuestionnaireItem({
            category: this.categoryId,
            userId: this.userId,
            name: this.name,
          });
          this.showToast(
            "success",
            "top-right",
            "Success!",
            "CheckIcon",
            "Successful operation"
          );
          this.closeModal();
          this.$emit("result", {
            result: data.result,
            category: this.categoryId,
          });
          this.removePreloader();
        }
      } catch (ex) {
        this.removePreloader();
        this.showToast(
          "danger",
          "top-right",
          "Failed!",
          "XIcon",
          "Failed operation"
        );
      }
    },
    closeModal() {
      this.$emit("close");
    },
  },
  mounted() {
    this.showModal = true;
  },
};
</script>