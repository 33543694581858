<template>
  <b-modal
    ref="modalName"
    modal-class="modal-primary"
    title-class="h3 text-white"
    title="TRACKING: POTENTIAL"
    size="xmd"
    @hidden="closeModal()"
    hide-footer
    centered
    no-close-on-backdrop
  >
    <template #default>
      <b-container>
        <b-row>
          <b-col v-if="data.account">
            <h5>Account</h5>
            <p
              class="rounded text-primary border-primary font-medium-1 text-center py-1"
            >
              {{ data.account }}
            </p>
          </b-col>
          <b-col>
            <h5>Client</h5>
            <p
              class="rounded text-primary border-primary font-medium-1 text-center py-1"
            >
              {{ data.client_name }}
            </p>
          </b-col>
        </b-row>

        <b-row>
          <br />
          <b-table :items="items" :fields="fieldsTracking">
            <template #cell(created_at)="data">
              <span>
                {{ data.value | myGlobalWithHour }}
              </span>
            </template>
            <template #cell(name_user)="data">
              <center>
                {{ data.value }}
              </center>
            </template>
            <template #cell(potencial_action)="data">
              <div class="text-center">
                <b-badge v-if="data.value" :variant="getBadgeColor(data.value)">
                  {{ data.value }}
                </b-badge>
              </div>
            </template>
            <template #cell(comment)="data">
              <div class="w-600">
                {{ data.value }}
              </div>
            </template>
          </b-table>
        </b-row>
      </b-container>
    </template>
  </b-modal>
</template>
<script>
import PotencialAppointmentService from "@/views/commons/components/potencial-appointment/services/potencial-appointments.service";
export default {
  name: "ModalTracking",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      items: [],
      fieldsTracking: [
        { key: "created_at", label: "Date", thStyle: { textAlign: "center" } },
        { key: "name_user", label: "User", thStyle: { textAlign: "center" } },
        {
          key: "potencial_action",
          label: "Action",
          thStyle: { textAlign: "center" },
        },
        {
          key: "comment",
          label: "Observation",
          tdClass: "w-25",
          thStyle: { textAlign: "center" },
        },
      ],
    };
  },
  created() {
    this.getTracking();
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async getTracking() {
      try {
        this.addPreloader();
        const data = await PotencialAppointmentService.getTracking({
          id: this.data.id,
        });
        this.toggleModal("modalName");
        this.items = data;
        this.removePreloader();
      } catch (ex) {
        this.removePreloader();
        console.log(ex);
      }
    },
    getBadgeColor(status) {
      const classes = {
        CREATED: "light-primary",
        ACCEPTED: "light-success",
        APPROVED: "light-success",
        FINISHED: "light-success",
        "BANK INFORMATION DONE": "light-info",
        REJECTED: "light-danger",
        DELETED: "light-danger",
        EXPIRED: "light-danger",
        PENDING: "light-warning",
        "PAID: YES": "light-success",
        "PAID: NO": "light-warning",
      };

      return classes[status] || "light-secondary";
    }
  },
};
</script>
<style scoped>
table tbody tr td .w200 {
  width: 200px !important;
}
.w-600 {
  max-width: 600px;
}
</style>