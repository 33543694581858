<template>
  <b-modal
    ref="modalName"
    modal-class="modal-primary"
    title-class="h3 text-white"
    title="Bank Information"
    size="lg"
    @hidden="closeModal()"
    :hide-footer="hiddeFooter"
    centered
    no-close-on-backdrop
  >
    <template #default>
      <b-container>
        <validation-observer ref="formBankInfo">
          <b-row>
            <b-col>
              <validation-provider rules="required" v-slot="{ errors }">
                <b-form-group label="BANK NAME">
                  <b-form-select
                    v-model="form.bank"
                    :options="banks"
                    :disabled="!_inSpecialist"
                    :class="errors[0] && validate ? 'border-danger' : ''"
                  />
                  <b-form-invalid-feedback :state="!(errors[0] && validate)">
                    select a bank.
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <validation-provider
                rules="required|validate-amount"
                v-slot="{ errors }"
              >
                <b-form-group label="LOAN AMOUNT">
                  <money
                    class="form-control"
                    v-bind="vMoney"
                    v-model="form.loan_amount"
                    :disabled="!_inSpecialist"
                    :class="errors[0] && validate ? 'border-danger' : ''"
                  />
                  <b-form-invalid-feedback :state="!(errors[0] && validate)">
                    enter the loan amount.
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <validation-provider
                rules="required|validate-amount"
                v-slot="{ errors }"
              >
                <b-form-group label="INTEREST %">
                  <money
                    class="form-control"
                    v-bind="vPercent"
                    v-model="form.interest"
                    :disabled="!_inSpecialist"
                    :class="errors[0] && validate ? 'border-danger' : ''"
                  />
                  <b-form-invalid-feedback :state="!(errors[0] && validate)">
                    enter interest.
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <validation-provider
                rules="required|is_numeric|min_one"
                v-slot="{ errors }"
              >
                <b-form-group label="TERMS (Months)">
                  <b-input
                    v-model="form.terms"
                    :class="errors[0] && validate ? 'border-danger' : ''"
                    :disabled="!_inSpecialist"
                  ></b-input>
                  <b-form-invalid-feedback :state="!(errors[0] && validate)">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <validation-provider
                rules="required|validate-amount"
                v-slot="{ errors }"
              >
                <b-form-group label="MONTHLY PAYMENT ($)">
                  <money
                    class="form-control"
                    v-bind="vMoney"
                    v-model="form.monthly_payment"
                    :disabled="!_inSpecialist"
                    :class="errors[0] && validate ? 'border-danger' : ''"
                  />
                  <b-form-invalid-feedback :state="!(errors[0] && validate)">
                    enter monthly payment
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <validation-provider rules="noHtmlTags" v-slot="{ errors }">
                <b-form-group label="COMMENTS (opcional):">
                  <b-form-textarea
                    rows="5"
                    v-model="form.comments"
                    no-resize
                    :disabled="!_inSpecialist"
                    :class="errors[0] && validate ? 'border-danger' : ''"
                  />
                  <b-form-invalid-feedback :state="!(errors[0] && validate)">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>
      </b-container>
    </template>
    <template #modal-footer>
      <b-container>
        <template v-if="_inSpecialist">
          <b-button
            size="lg"
            variant="success"
            @click="saveData(1)"
            class="float-right mr-1"
          >
            SAVE
          </b-button>
        </template>
      </b-container>
    </template>
  </b-modal>
</template>
<script>
import { VMoney } from "v-money";
import { mapActions, mapGetters } from "vuex";
// import potencialAppointmentsService from "../../services/potencial-appointments.service";

export default {
  name: "BankInfoModal",
  directives: { money: VMoney },
  props: {
    rowData: { type: Object, required: true },
    inSpecialist: { type: Boolean, required: true },
  },
  data() {
    return {
      vMoney: {
        decimal: ".",
        thousand: ",",
        prefix: "$ ",
        precision: 2,
        min: 0.0,
      },
      vPercent: {
        decimal: ".",
        thousand: ",",
        suffix: " %",
        precision: 2,
        min: 0.0,
        max: 100,
      },
      form: {
        bank: null,
        loan_amount: 0,
        interest: 0,
        terms: null,
        comments: null,
        status_done: 0,
        monthly_payment: 0,
      },
      validate: false,
    };
  },
  computed: {
    ...mapGetters({
      banks: "DebtSolutionApplications/banks",
      currentUser: "auth/currentUser",
    }),
    _inSpecialist() {
      return this.inSpecialist && this.rowData.status_done === 0;
    },
    dataProvider() {
      return {
        b_id: this.rowData.id,
        b_ca_id: this.rowData.client_account_id,
        b_comments: this.form.comments,
        b_loan_amount: this.form.loan_amount,
        b_iduser: this.currentUser.user_id,
        b_interest: this.form.interest,
        b_name_bank: this.form.bank,
        b_done_status: 0,
        b_terms: Number(this.form.terms),
        b_monthly_payment: Number(this.form.monthly_payment),
      };
    },
    hiddeFooter() {
      return !this._inSpecialist;
    },
  },
  mounted() {},
  async created() {
    await this.getDataBank();
  },
  methods: {
    ...mapActions({
      getBanks: "DebtSolutionApplications/getBanksAction",
    }),
    closeModal() {
      this.$emit("close");
    },
    async saveData(status_done) {
      try {
        this.validate = true;
        const isValid = await this.$refs.formBankInfo.validate();
        if (!isValid) throw new Error("required fields");
        this.$emit("send-data", {
          ...this.dataProvider,
          b_done_status: status_done,
        });
      } catch (ex) {
        this.showToast(
          "warning",
          "top-right",
          "Warning",
          "AlertTriangleIcon",
          ex.message
        );
      }
    },

    async getDataBank() {
      try {
        if (this.rowData) {
          this.addPreloader();
          this.form.bank = this.rowData.bank_id;
          this.form.comments = this.rowData.comments;
          this.form.loan_amount = Number(this.rowData.loan_amount);
          this.form.interest = Number(this.rowData.interest);
          this.form.monthly_payment = Number(this.rowData.monthly_payment);
          this.form.status_done = this.rowData.status_done;
          this.form.terms = this.rowData.term_months;
          let t_charge = this.rowData.type ? this.rowData.type : 4;
          await this.getBanks({ t_charge });
          this.removePreloader();
          this.toggleModal("modalName");
        }
      } catch (ex) {}
    },
  },
};
</script>