<template>
  <div>
    <b-row>
      <b-col cols="6">
        <validation-provider rules="required" v-slot="{ errors }">
          <b-form-group label="Employer's name">
            <b-form-input
              :disabled="disabled"
              v-model="form.employers_name"
              :class="errors[0] && validate ? 'border-danger' : ''"
            ></b-form-input>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col cols="6">
        <validation-provider rules="required" v-slot="{ errors }">
          <b-form-group label="Employment status">
            <v-select
              :disabled="disabled"
              v-model="form.employment_status_id"
              :options="questions.employmentStatus"
              label="name"
              :class="errors[0] && validate ? 'border-danger-select' : ''"
              :reduce="(x) => x.id"
            ></v-select>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="3">
        <validation-provider rules="required" v-slot="{ errors }">
          <b-form-group label="Time at current work">
            <b-form-input
              :disabled="disabled"
              v-model="form.time_at_current_work"
              type="number"
              :class="errors[0] && validate ? 'border-danger' : ''"
              placeholder="number of months"
            ></b-form-input>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col cols="3">
        <validation-provider rules="required" v-slot="{ errors }">
          <b-form-group label="Payment period">
            <v-select
              :disabled="disabled"
              v-model="form.payment_period"
              :searchable="false"
              :options="questions.paymentPeriodo"
              :reduce="(x) => x.value"
              label="text"
              :class="errors[0] && validate ? 'border-danger-select' : ''"
            ></v-select>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col cols="6">
        <validation-provider rules="required" v-slot="{ errors }">
          <b-form-group label="Occupation">
            <b-input-group>
              <v-select
                :disabled="disabled"
                v-model="form.occupation_id"
                style="flex: 1"
                :options="questions.occupations"
                label="name"
                :reduce="(x) => x.id"
                :class="errors[0] && validate ? 'border-danger-select' : ''"
              ></v-select>
              <b-input-group-append v-if="!disabled">
                <b-button
                  variant="info"
                  size="sm"
                  @click.prevent="addOccupation"
                >
                  <feather-icon icon="PlusIcon" size="20" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="3">
        <validation-provider
          rules="required|validate-amount"
          v-slot="{ errors }"
        >
          <b-form-group label="Amount of each period">
            <money
              :disabled="disabled"
              class="form-control"
              v-bind="vMoney"
              v-model="form.amount_of_each_period"
              :class="errors[0] && validate ? 'border-danger' : ''"
            />
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col cols="3">
        <validation-provider
          rules="required|validate-amount"
          v-slot="{ errors }"
        >
          <b-form-group label="Amount needed">
            <money
              :disabled="disabled"
              class="form-control"
              v-bind="vMoney"
              v-model="form.amount_needed"
              :class="errors[0] && validate ? 'border-danger' : ''"
            />
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col cols="3">
        <validation-provider rules="required" v-slot="{ errors }">
          <b-form-group label="Paystub" :disabled="disabled">
            <b-form-radio-group
              v-model="form.paystub"
              style="width: 100%"
              buttons
              :button-variant="
                errors[0] && validate ? 'outline-danger' : 'outline-primary'
              "
              :options="questions.optionsRadio"
              name="radio-inline"
            ></b-form-radio-group>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col cols="3">
        <validation-provider rules="required" v-slot="{ errors }">
          <b-form-group label="Bank statements" :disabled="disabled">
            <b-form-radio-group
              v-model="form.bank_statements"
              style="width: 100%"
              buttons
              :button-variant="
                errors[0] && validate ? 'outline-danger' : 'outline-primary'
              "
              :options="questions.optionsRadio"
              name="radio-inline"
            >
            </b-form-radio-group>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6" v-if="validTypeForm">
        <b-form-group label="Colatteral Assets">
          <b-row class="mb-1">
            <b-col
              cols="2"
              class="d-flex align-items-center pr-0 justify-content-end"
            >
              <p
                for="auto_marked"
                class="mb-0 text-right marked"
                style="font-size: 1rem"
              >
                Auto :
              </p>
            </b-col>
            <b-col cols="10">
              <b-input-group prepend="Market value">
                <b-form-input
                  :disabled="disabled"
                  id="auto_marked"
                  v-model="form.colatteral_assets.auto"
                  type="number"
                ></b-form-input>
              </b-input-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="2"
              class="d-flex align-items-center pr-0 justify-content-end"
            >
              <p
                for="home_marked"
                class="mb-0 text-right marked"
                style="font-size: 1rem"
              >
                Home :
              </p>
            </b-col>
            <b-col cols="10">
              <b-input-group prepend="Market value">
                <b-form-input
                  :disabled="disabled"
                  type="number"
                  id="home_marked"
                  v-model="form.colatteral_assets.home"
                ></b-form-input>
              </b-input-group>
            </b-col>
          </b-row>
        </b-form-group>
      </b-col>
      <b-col :cols="validTypeForm ? 6 : 8">
        <b-form-group
          label="Bank Account Information"
          v-slot="{ ariaDescribedby }"
        >
          <b-row class="mb-1">
            <b-col>
              <b-input-group prepend="Routing">
                <b-form-input
                  type="number"
                  :disabled="disabled"
                  v-model="form.bank_account_information.routing"
                  :aria-describedby="ariaDescribedby"
                ></b-form-input>
              </b-input-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-input-group prepend="Account">
                <b-form-input
                  type="number"
                  :disabled="disabled"
                  v-model="form.bank_account_information.account"
                  :aria-describedby="ariaDescribedby"
                ></b-form-input>
              </b-input-group>
            </b-col>
          </b-row>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <validation-provider rules="required" v-slot="{ errors }">
          <b-form-group label="Prior tax return" :disabled="disabled">
            <!-- <b-row class="mb-1">
              <b-col>
                <b-form-radio-group
                  v-model="form.prior_tax_return"
                  style="width: 100%"
                  buttons
                  :button-variant="
                    errors[0] && validate ? 'outline-danger' : 'outline-primary'
                  "
                  :options="questions.priorTaxReturn"
                  name="radio-inline"
                ></b-form-radio-group>
              </b-col>
            </b-row> -->
            <b-row class="mb-1">
              <b-col cols="6">
                <b-input-group prepend="Year">
                  <b-form-input
                    type="text"
                    :disabled="disabled"
                    v-model="form.prior_tax_return.year"
                    :aria-describedby="ariaDescribedby"
                  ></b-form-input>
                </b-input-group>
              </b-col>
              <b-col cols="6">
                <b-input-group prepend="Amount">
                  <b-form-input
                    type="number"
                    :disabled="disabled"
                    v-model="form.prior_tax_return.amount"
                    :aria-describedby="ariaDescribedby"
                  ></b-form-input>
                </b-input-group>
              </b-col>
            </b-row>
            <b-row
              v-if="!validTypeForm && (!statusDone || dataFiles.length > 0)"
            >
              <b-col>
                <upload-files-form
                  :statusDone="statusDone"
                  :dataFiles="dataFiles"
                  @close="getItemsFiles"
                  @refresh="() => $emit('refresh')"
                />
              </b-col>
            </b-row>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col
        cols="3"
        v-if="
          isNotAMortgageLoan &&
          validTypeForm &&
          (!statusDone || dataFiles.length > 0)
        "
      >
        <b-form-group :label="titleUploadFile">
          <upload-files-form
            :statusDone="statusDone"
            :dataFiles="dataFiles"
            @close="getItemsFiles"
            @refresh="() => $emit('refresh')"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { VMoney } from "v-money";
import UploadFilesForm from "@/views/commons/components/potencial-appointment/components/modals/Form/UploadFilesForm.vue";
export default {
  name: "FormMain",
  directives: { money: VMoney },
  components: {
    UploadFilesForm,
  },
  props: {
    infoForm: {
      type: Object,
      required: true,
    },
    questions: {
      type: Object,
      required: true,
    },
    typeForm: {
      type: String,
      required: true,
    },
    validate: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dataFiles: [],
      vMoney: {
        decimal: ".",
        thousand: ",",
        prefix: "$ ",
        precision: 2,
        min: 0.0,
      },
      form: {
        id: 0,
        employers_name: "",
        employment_status_id: "",
        time_at_current_work: "",
        payment_period: "",
        occupation_id: "",
        amount_of_each_period: 0,
        amount_needed: 0,
        paystub: "",
        bank_statements: "",
        colatteral_assets: {
          auto: "",
          home: "",
        },
        bank_account_information: {
          routing: "",
          account: "",
        },
        prior_tax_return: {
          year: "",
          amount: "",
        },
      },
    };
  },
  computed: {
    validTypeForm() {
      return this.typeForm != "AUTO LOAN";
    },
    isNotAMortgageLoan() {
      return this.typeForm != "MORTGAGE LOAN";
    },
    statusDone() {
      return this.infoForm.potential_appointment_forms
        ? this.infoForm.potential_appointment_forms.status_done === "SEND"
        : false;
    },
    titleUploadFile() {
      return this.statusDone ? "Show Files" : "Upload Files";
    },
  },
  watch: {
    "questions.occupations": {
      handler(data) {
        /** esto se cambiara cuando agrege un nuevo registro */
        this.form.occupation_id = data[data.length - 1].id;
      },
      deep: true,
    },
    form: {
      handler(value) {
        this.$emit("dataForms", {
          fm: value,
        });
      },
      deep: true,
      immediate: true,
    },
    infoForm: {
      handler(value) {
        if (value.potential_appointment_forms) {
          const { files } = value.potential_appointment_forms;
          this.dataFiles = files ? files : [];
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    let ocupationsOptions = this.questions.occupations;
    this.occupationsData = ocupationsOptions;
  },
  methods: {
    addOccupation() {
      try {
        this.$emit("addQuestionnaire", {
          categoryId: 2,
          title: "Add Occupation",
        });
      } catch (ex) {}
    },
    getItemsFiles(items) {
      this.$emit("getItemsFiles", items);
    },
  },
  mounted() {
    const { potential_appointment_forms } = this.infoForm;
    if (potential_appointment_forms) {
      const paf = potential_appointment_forms;
      this.form.id = paf.id;
      this.form.employers_name = paf.employers_name;
      this.form.employment_status_id = paf.employment_status_id;
      this.form.time_at_current_work = paf.time_at_current_work;
      this.form.payment_period = paf.payment_period;
      this.form.occupation_id = paf.occupation_id;
      this.form.amount_of_each_period = paf.amount_of_each_period;
      this.form.amount_needed = paf.amount_needed;
      this.form.paystub = paf.paystub;
      this.form.bank_statements = paf.bank_statements;
      this.form.colatteral_assets = paf.colatteral_assets;
      this.form.bank_account_information = paf.bank_account_information;
      this.form.prior_tax_return = paf.prior_tax_return;
      this.dataFiles = potential_appointment_forms.files
        ? potential_appointment_forms.files
        : [];
    } else {
      this.form.amount_needed = this.infoForm.amount_needed;
    }
  },
};
</script>