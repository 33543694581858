<template>
  <b-modal
    ref="modalName"
    modal-class="modal-primary"
    title-class="h3 text-white"
    :title="`ATTEND ${item.type_description.toUpperCase()}`"
    size="sm"
    @hidden="closeModal()"
    centered
    no-close-on-backdrop
  >
    <b-row>
      <b-col cols="12">
        <b-form-group label="Enter your resume:">
          <b-form-textarea id="message" v-model="comment" rows="10" />
        </b-form-group>
      </b-col>
    </b-row>

    <template #modal-footer>
      <b-button
        class="d-flex justify-content-center"
        variant="primary"
        :disabled="comment == null"
        @click="attend()"
      >
        SAVE
      </b-button>
    </template>
  </b-modal>
</template>
<script>
// SERVICES
import AppointmentService from "@/views/commons/components/appointments/service/appointment.service";

// STORE
import { mapGetters } from "vuex";

export default {
  props: {
    item: Object,
    redirectCompleted: {
      type: Boolean,
      required: false,
      default: true,
    }
  },
  data() {
    return {
      comment: null,
    };
  },
  mounted() {
    this.toggleModal("modalName");
  },
  created() {
    console.log(this.item);
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    async attend() {
      try {
        this.addPreloader();
        let result = await this.updateAppointmentStatus("accept");
        if (result) {
          this.showSuccessSwal();
          this.$emit("refresh");
          if(this.redirectCompleted){
            this.$router.push({ name: "sp-appointment-accepted" });
          }
        }
      } catch (error) {
        this.showErrorSwal();
      } finally {
        this.closeModal();
        this.removePreloader();
      }
    },
    closeModal() {
      this.$emit("close");
    },
    async updateAppointmentStatus(status) {
      const { data } = await AppointmentService.updateAppointmentStatus({
        type: "app_status",
        appointment_id: this.item.id,
        button_selected: status,
        created_by: this.currentUser.user_id,
        motive: this.comment,
        type_charge: this.item.type_id,
      });
      return data;
    },
  },
};
</script>