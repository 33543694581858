<template>
  <div class="p-2">
    <b-nav pills class="mb-0 custom-tab-amg">
      <b-nav-item
        :active="typeService === 1"
        exact-active-class="active border-radius-tabs"
        :link-classes="['h-full px-3', bgTabsNavs]"
        @click="typeService = 1"
        exact
      >
        Credit Cards
        <b-badge
          v-if="G_COUNTER_CREDIT_CARDS > 0"
          pill
          variant="danger"
          class="ml-1"
        >
          {{ G_COUNTER_CREDIT_CARDS > 99 ? "99+" : G_COUNTER_CREDIT_CARDS }}
        </b-badge>
      </b-nav-item>
      <b-nav-item
        :active="typeService === 2"
        exact
        exact-active-class="active border-radius-tabs"
        :link-classes="['h-full px-3', bgTabsNavs]"
        @click="typeService = 2"
      >
        Appointments
        <b-badge
          v-if="G_COUNTER_APPOINTMENTS > 0"
          pill
          variant="danger"
          class="ml-1"
        >
          {{ G_COUNTER_APPOINTMENTS > 99 ? "99+" : G_COUNTER_APPOINTMENTS }}
        </b-badge>
      </b-nav-item>
      <b-nav-item
        v-if="inSpecialist"
        :active="typeService === 4"
        exact
        exact-active-class="active border-radius-tabs"
        :link-classes="['h-full px-3', bgTabsNavs]"
        @click="typeService = 4"
      >
        Potential
        <b-badge
          v-if="G_COUNTER_POTENTIAL > 0"
          pill
          variant="danger"
          class="ml-1"
        >
          {{ G_COUNTER_POTENTIAL > 99 ? "99+" : G_COUNTER_POTENTIAL }}
        </b-badge>
      </b-nav-item>
      <b-nav-item
        :active="typeService === 3"
        exact
        exact-active-class="active border-radius-tabs"
        :link-classes="['h-full px-3', bgTabsNavs]"
        @click="typeService = 3"
      >
        Other Services
        <b-badge
          v-if="G_COUNTER_OTHER_SERVICES > 0"
          pill
          variant="danger"
          class="ml-1"
        >
          {{ G_COUNTER_OTHER_SERVICES > 99 ? "99+" : G_COUNTER_OTHER_SERVICES }}
        </b-badge>
      </b-nav-item>
    </b-nav>
    <b-container
      fluid
      no-body
      class="mt-0 pt-2 border-top-primary border-bottom-primary border-left-primary border-right-primary border-2 rounded-sm"
    >
      <view-services :type-service="typeService" :key="keyTable" />
    </b-container>
  </div>
</template>
<script>
import ViewServices from "@/views/commons/components/clients/dashboard/options/task-notes-module/modals/ViewServicesComponent.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    ViewServices,
  },
  data() {
    return {
      keyTable: 0,
      typeService: 1,
    };
  },
  watch: {
    typeService(newVal) {
      this.keyTable += 1;
    },
  },
  computed: {
    ...mapGetters({
      client: "DebtSolutionClients/G_CLIENTS",
      G_COUNTER_CREDIT_CARDS: "clients-store/G_COUNTER_CREDIT_CARDS",
      G_COUNTER_APPOINTMENTS: "clients-store/G_COUNTER_APPOINTMENTS",
      G_COUNTER_POTENTIAL: "clients-store/G_COUNTER_POTENTIAL",
      G_COUNTER_OTHER_SERVICES: "clients-store/G_COUNTER_OTHER_SERVICES",
    }),
    inSpecialist() {
      return [28, 29, 30].includes(this.$route.matched[0]?.meta?.module);
    },
  },
  async created() {
    await this.A_GET_ALL_SERVICES_COUNTER({
      client_account_id: this.client.id,
      module_id: this.$route.matched[0]?.meta?.module,
    });
  },
  methods: {
    ...mapActions({
      A_GET_ALL_SERVICES_COUNTER: "clients-store/A_GET_ALL_SERVICES_COUNTER",
    }),
  },
};
</script>
