<template>
  <b-modal
    v-model="visible"
    id="personal-info"
    size="xlg"
    @hidden="closeModal"
    :modal-class="
      isDarkSkin
        ? 'bg-modal-binnacle-dark bg-modal-binnacle-ca'
        : 'bg-modal-binnacle-light bg-modal-binnacle-ca'
    "
    title="POTENTIAL FORM"
    title-class="h3 text-white"
    no-close-on-backdrop
    centered
    :hide-footer="disabled"
  >
    <template #modal-header>
      <h5 class="modal-title h3 text-white">POTENTIAL FORM</h5>
      <b-button aria-label="Close" class="close" size="sm" @click="closeModal">
        <feather-icon icon="XIcon" size="20" />
      </b-button>
    </template>
    <b-container fluid id="formPotential">
      <b-row>
        <!-- PERSONAL INFORMATION -->
        <b-col cols="5">
          <div :class="isDarkSkin ? 'black' : 'light'" class="rounded">
            <div class="p-2">
              <h3 class="text-primary" style="font-weight: bold">
                Personal Information
              </h3>
              <hr style="border: 1px solid #0090e7" />
              <template v-for="item in personalInformation">
                <b-row class="content-att my-1" :key="item.label">
                  <span class="col-md-4 text-truncate"
                    ><feather-icon :icon="item.icon" size="20" class="mr-1" />
                    {{ item.label }}
                  </span>
                  <div class="col-md-8">
                    <strong v-if="item.label != 'DOCUMENTS'">{{
                      item.value
                    }}</strong>
                    <strong
                      v-else
                      v-for="(valor, clave) in item.value"
                      :key="clave"
                    >
                      <template v-if="valor != null">
                        {{ clave.toUpperCase() }}: {{ valor }}
                      </template>
                    </strong>
                  </div>
                </b-row>
              </template>
            </div>
          </div>
        </b-col>

        <!-- FINANCIAL INFORMATION -->
        <b-col cols="7">
          <div :class="isDarkSkin ? 'black' : 'light'" class="rounded">
            <div class="p-2">
              <h3 class="text-primary" style="font-weight: bold">
                {{ typeForm }}
              </h3>
              <hr style="border: 1px solid #0090e7" />
              <div>
                <validation-observer ref="formPotential">
                  <FormMain
                    :disabled="disabled"
                    :validate="validate"
                    :infoForm="infoForm"
                    :questions="questions"
                    :typeForm="typeForm"
                    @addQuestionnaire="addQuestionnaire"
                    @dataForms="setDataForms"
                    @getItemsFiles="getItemsFiles"
                    @refresh="refresh"
                  />
                  <template v-if="validMortgageLoan">
                    <MortGageLoanForm
                      :disabled="disabled"
                      :validate="validate"
                      :infoForm="infoForm"
                      :optionsPropertyTypes="questions.propertyTypes"
                      @addQuestionnaire="addQuestionnaire"
                      @dataForms="setDataForms"
                    />
                  </template>
                  <template v-if="validAutoLoan">
                    <AutoLoanForm
                      :disabled="disabled"
                      :validate="validate"
                      :infoForm="infoForm"
                      @dataForms="setDataForms"
                    />
                  </template>
                  <template v-if="validPersonalLoan">
                    <PersonalLoanForm
                      :disabled="disabled"
                      :validate="validate"
                      :infoForm="infoForm"
                      :optionsLoanPurpose="questions.personalLoanPurpose"
                      @dataForms="setDataForms"
                    />
                  </template>
                </validation-observer>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <template #modal-footer>
      <b-button
        size="md"
        variant="outline-success"
        @click="saveData('SAVE')"
        class="mx-1"
      >
        SAVE
      </b-button>
      <b-button
        size="md"
        variant="outline-success"
        @click="saveData('SEND')"
        class="mx-1"
      >
        DONE
      </b-button>
    </template>
    <CreateQuestionnaireModal
      v-if="modalCreateQuestionnaire.show"
      :categoryId="modalCreateQuestionnaire.category"
      :titleModal="modalCreateQuestionnaire.title"
      :userId="modalCreateQuestionnaire.userId"
      @close="closeModalQuestionnaire"
      @result="resultQuestionnaire"
    />
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex";

// SERVICES
import PotentialFormAppService from "@/views/commons/components/potencial-appointment/services/potencial-appointment-forms.services";
import AutoLoanForm from "@/views/commons/components/potencial-appointment/components/modals/Form/AutLoanForm.vue";
import MortGageLoanForm from "@/views/commons/components/potencial-appointment/components/modals/Form/MortGageLoanForm.vue";
import PersonalLoanForm from "@/views/commons/components/potencial-appointment/components/modals/Form/PersonalLoanForm.vue";
import FormMain from "@/views/commons/components/potencial-appointment/components/modals/Form/FormMain.vue";
import CreateQuestionnaireModal from "@/views/commons/components/potencial-appointment/components/modals/Form/CreateQuestionnaireModal.vue";
import { VMoney } from "v-money";
export default {
  directives: { money: VMoney },
  props: {
    clientData: {
      type: Object,
      required: true,
    },
  },
  components: {
    AutoLoanForm,
    MortGageLoanForm,
    PersonalLoanForm,
    FormMain,
    CreateQuestionnaireModal,
  },
  data() {
    return {
      itemsFiles: [],
      validate: false,
      infoForm: {},
      visible: false,
      questions: {
        propertyTypes: [],
        employmentStatus: [],
        occupations: [],
        personalLoanPurpose: [],
        paymentPeriodo: [
          { value: "MONTHLY", text: "Monthly" },
          { value: "WEEKLY", text: "Weekly" },
          { value: "BIWEEKLY", text: "Biweekly" },
        ],
        optionsRadio: [
          { text: "Yes", value: "YES" },
          { text: "No", value: "NO" },
        ],
        priorTaxReturn: [
          { value: "YEAR", text: "Year" },
          { value: "AMOUNT", text: "Amount" },
        ],
      },
      icons: [
        "UserIcon",
        "BoxIcon",
        "GridIcon",
        "MapPinIcon",
        "MapPinIcon",
        "FileTextIcon",
        "CalendarIcon",
        "PhoneIcon",
        "ShieldIcon",
        "DollarSignIcon",
      ],
      label: [
        "LEGAL NAME",
        "SERVICE",
        "MAILING ADDRESSES",
        "ANOTHER ADDRESSES",
        "DOCUMENTS",
        "DOB",
        "PHONE NUMBER",
        "LEGAL STATUS",
        "AMOUNT",
      ],
      personalInformation: [],
      yearsList: [],
      vMoney: {
        decimal: ",",
        thousand: ".",
        prefix: "$ ",
        suffix: "",
        precision: 2,
        min: 0.0,
        masked: false,
      },
      validate: false,
      timeOptions: [],
      schedule: [],
      typeCharges: {
        148: "MORTGAGE LOAN",
        147: "AUTO LOAN",
        146: "PERSONAL LOAN",
      },
      modalCreateQuestionnaire: {
        show: false,
        title: "",
        userId: 1,
        category: 0,
      },
      providerForm: {
        paf: null,
        pmlf: null,
        palf: null,
        pplf: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    disabled() {
      return this.clientData.status_done === "SEND";
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    dataProvider() {
      const { paf, palf, pmlf, pplf } = this.providerForm;
      return {
        b_paf: paf ? JSON.stringify(paf) : null,
        b_pal: palf ? JSON.stringify(palf) : null,
        b_pml: pmlf ? JSON.stringify(pmlf) : null,
        b_ppl: pplf ? JSON.stringify(pplf) : null,
        b_potential_appointment_id: this.clientData.id,
        b_user_id: this.currentUser.user_id,
        b_client_account_id: this.clientData.account_id,
        b_type_form: this.typeForm.split(" ")[0],
      };
    },
    typeForm() {
      return this.typeCharges[this.infoForm.type_charge_id];
    },
    validMortgageLoan() {
      return this.typeCharges[this.infoForm.type_charge_id] === "MORTGAGE LOAN";
    },
    validAutoLoan() {
      return this.typeCharges[this.infoForm.type_charge_id] === "AUTO LOAN";
    },
    validPersonalLoan() {
      return this.typeCharges[this.infoForm.type_charge_id] === "PERSONAL LOAN";
    },
  },
  async created() {
    await this.start();
  },
  methods: {
    async start() {
      try {
        this.addPreloader();
        await this.getInformation();
        await this.getQuestionnaireItems();
        this.removePreloader();
        this.visible = true;
      } catch (ex) {
        this.removePreloader();
        this.closeModal();
      }
    },
    async refresh() {
      await this.getInformation();
      await this.getQuestionnaireItems();
    },
    async getInformation() {
      const dataForm = await PotentialFormAppService.getForm({
        potential_appointment_id: this.clientData.id,
      });
      this.infoForm = dataForm;
      this.personalInformation = Object.keys(dataForm)
        .filter((key) => this.getLabels().includes(key))
        .map((key, index) => ({
          label: this.label[index],
          value: dataForm[key],
          icon: this.icons[index],
        }));
    },
    async getQuestionnaireItems() {
      const result = await PotentialFormAppService.getQuestionnaireItems({
        categories: [1, 2, 4, 9],
      });
      this.questions.employmentStatus = result.employment_status;
      this.questions.occupations = result.occupation;
      this.questions.personalLoanPurpose = result.personal_loan_purpose;
      this.questions.propertyTypes = result.property_type;
    },
    async saveData(status_done) {
      try {
        if (status_done == "SEND") {
          this.validate = true;
          const isValid = await this.$refs.formPotential.validate();
          if (!isValid) throw new Error("required fields");
        }
        this.addPreloader();
        let params = {
          b_status_done: status_done,
          ...this.dataProvider,
        };
        let formData = new FormData();
        for (let key in params) {
          formData.append(key, params[key]);
        }

        for (let file in this.itemsFiles) {
          formData.append(
            "files[]",
            this.itemsFiles[file].file,
            this.itemsFiles[file].name
          );
        }
        const result = await PotentialFormAppService.store(formData);
        this.itemsFiles = [];
        this.closeModal();
        this.removePreloader();
        this.$emit("refresh");
      } catch (ex) {
        this.removePreloader();
        this.showToast(
          "warning",
          "top-right",
          "Warning",
          "AlertTriangleIcon",
          ex.message
        );
      }
    },
    closeModal() {
      const filesToBeUploaded = this.itemsFiles.filter(
        (file) => !file.status_done
      );

      if (filesToBeUploaded.length > 0) {
        this.validatePendingFiles();
      } else {
        this.$emit("closeModal");
        this.visible = false;
      }
    },
    getLabels() {
      return this.label.map((label) => label.replace(/ /g, "_").toLowerCase());
    },
    addQuestionnaire(data) {
      const { title, categoryId } = data;
      this.modalCreateQuestionnaire.show = true;
      this.modalCreateQuestionnaire.title = title;
      this.modalCreateQuestionnaire.userId = 1;
      this.modalCreateQuestionnaire.category = categoryId;
    },
    closeModalQuestionnaire() {
      this.modalCreateQuestionnaire.show = false;
      this.modalCreateQuestionnaire.title = "";
      this.modalCreateQuestionnaire.userId = 1;
      this.modalCreateQuestionnaire.category = 0;
    },
    resultQuestionnaire(data) {
      try {
        const { category, result } = data;
        switch (category) {
          case 2:
            this.questions.occupations.push(result[0]);
            break;
          case 9:
            this.questions.propertyTypes.push(result[0]);
            break;
        }
      } catch (ex) {}
    },
    setDataForms(data) {
      try {
        const { fm, alf, plf, mlf } = data;
        this.providerForm.paf = fm ? fm : this.providerForm.paf;
        this.providerForm.palf = alf ? alf : this.providerForm.palf;
        this.providerForm.pplf = plf ? plf : this.providerForm.pplf;
        this.providerForm.pmlf = mlf ? mlf : this.providerForm.pmlf;
      } catch (ex) {}
    },
    getItemsFiles(items) {
      this.itemsFiles = items;
    },
    async validatePendingFiles() {
      const { isConfirmed, isDenied } = await this.showQuestionSwal(
        `Do you want to save the ${this.itemsFiles.length} files currently being loaded?`,
        null,
        "Yes",
        "Cancel",
        "question",
        {
          allowEscapeKey: false,
          allowOutsideClick: false,
          showCloseButton: false,
          showDenyButton: true,
          denyButtonText: "No",
          customClass: {
            confirmButton: "btn btn-outline-success mr-1",
            denyButton: "btn btn-outline-danger mr-1",
            cancelButton: "btn btn-outline-warning  ",
          },
          focusConfirm: false,
        }
      );
      if (isConfirmed) {
        await this.saveData("SAVE");
      }
      if (isDenied) {
        this.itemsFiles = [];
        this.closeModal();
      }
    },
  },
};
</script>
<style lang="scss">
#formPotential {
  .black {
    background: #1a1c25;
  }
  .light {
    background: #f1f4f7;
    .vs__dropdown-toggle {
      background: #fff !important;
    }
  }
  .black {
    .marked {
      color: rgba(255, 255, 255, 0.8) !important;
    }
  }
  .border-danger-select {
    .vs__dropdown-toggle {
      border-color: #fc424a !important;
    }
  }
  .v-select.vs--disabled {
    .vs__dropdown-toggle {
      background-color: transparent !important;
    }
  }
}
</style>
