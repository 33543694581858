<template>
  <div>
    <b-button variant="info" @click="openModal" size="sm">
      <feather-icon
        :icon="statusDone ? 'EyeIcon' : 'UploadIcon'"
        size="20"
      ></feather-icon>
      <b-badge v-if="totalFiles > 0" variant="danger" class="ml-1"
        >{{ totalFiles }} <span class="sr-only">unread messages</span></b-badge
      >
    </b-button>
    <b-modal
      v-model="visible"
      size="lg"
      @hidden="close"
      title="Files"
      title-tag="h3"
      modal-class="modal-primary"
      hide-footer
      centered
      no-close-on-backdrop
    >
      <b-container>
        <template v-if="!statusDone">
          <b-row class="justify-content-end">
            <b-button
              @click="changeStateAddFile"
              :class="addFile ? 'bg-danger' : 'bg-success'"
              size="md"
              style="border: none; font-size: 15px"
            >
              <feather-icon
                :icon="addFile ? 'MinusIcon' : 'PlusIcon'"
                size="15"
              />
              {{ this.addFile ? "Hide" : "Add File" }}
            </b-button> </b-row
          ><br />
          <b-row v-if="addFile">
            <drag-and-drop
              ref="dyd"
              class="w-100 mb-1"
              v-model="files"
              :filesArray="files"
            />

            <b-col>
              <b-row class="justify-content-center">
                <b-button
                  variant="warning"
                  @click="uploadFiles"
                  :disabled="files.length === 0"
                >
                  <feather-icon icon="UploadCloudIcon" /> ADD
                </b-button>
              </b-row>
            </b-col>
          </b-row>
          <br />
        </template>
        <b-row>
          <b-col cols="12" class="px-0">
            <b-table :items="items" :fields="fields" ref="FileTable" show-empty>
              <template #cell(file_name)="{ item }">
                <feather-icon :icon="fileIcon(item.file_type)" />
                <a :href="item.file_url" target="_blank" class="ml-1">{{
                  item.file_name
                }}</a>
              </template>
              <template #cell(actions)="{ item, index }">
                <center>
                  <feather-icon
                    size="18"
                    class="text-danger cursor-pointer"
                    icon="Trash2Icon"
                    @click="deleteFile(item, index)"
                  />
                </center>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
import DragAndDrop from "@/views/commons/utilities/DragAndDrop.vue";
import { mapGetters } from "vuex";
import PotentialFormAppService from "@/views/commons/components/potencial-appointment/services/potencial-appointment-forms.services";

export default {
  name: "UploadFilesForm",
  props: {
    dataFiles: {
      type: Array,
      required: true,
    },
    statusDone: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    DragAndDrop,
  },
  data() {
    return {
      visible: false,
      addFile: false,
      items: [],
      files: [],
      hasFile: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    fields() {
      let fields = [
        {
          key: "file_name",
          label: "File Name",
          thClass: "text-left",
          visible: true,
        },
        {
          key: "file_size",
          label: "Size",
          thClass: "text-left",
          visible: true,
        },
        {
          key: "upload_by",
          label: "Upload By",
          thClass: "text-left",
          visible: true,
        },
        {
          key: "actions",
          label: "Actions",
          thClass: "text-center",
          visible: true,
        },
      ];
      let findFieldActions = fields.findIndex((x) => x.key === "actions");
      if (findFieldActions > 0 && this.statusDone) {
        fields[findFieldActions].visible = false;
      }
      return fields.filter((x) => x.visible);
    },
    totalFiles() {
      return this.items.length;
    },
  },
  watch: {
    dataFiles: {
      handler(value) {
        let files = [];
        value.forEach((item) => {
          files.push(item);
        });
        if (this.items.length === 0) {
          this.items = files;
        } else {
          let newFiles = this.items.filter((x) => !x.status_done);
          this.items = files;
          this.items = this.items.concat(newFiles);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    changeStateAddFile() {
      this.addFile = !this.addFile;
    },
    close() {
      this.visible = false;
      this.$emit(
        "close",
        this.items.filter((x) => !x.status_done)
      );
    },
    openModal() {
      this.visible = true;
    },
    uploadFiles() {
      try {
        this.addFile = !this.addFile;
        this.files.forEach((file) => {
          this.items.push({
            file_name: file.name,
            file_size: this.getSizeFileFormat(file.size),
            upload_by: this.currentUser.fullName,
            status_done: false,
            file_type: file.type ? file.type.split("/")[1] : "",
            file,
          });
        });
        this.files = [];
      } catch (ex) {
        console.log(ex);
      }
    },
    fileIcon(doc) {
      switch (doc.toLowerCase()) {
        case "pdf":
          return "FileTextIcon";
          break;
        case "docx":
          return "FileTextIcon";
          break;
        case "xlsx":
          return "FileTextIcon";
          break;
        case "png":
          return "ImageIcon";
          break;
        case "jpg":
          return "ImageIcon";
          break;
        default:
          return "FileIcon";
          break;
      }
    },
    getSizeFileFormat(bytes, precision = 2) {
      const units = ["B", "KB", "MB", "GB", "TB"];
      bytes = Math.max(bytes, 0);
      let _pow = Math.floor(Math.log(bytes) / Math.log(1024));
      _pow = Math.min(_pow, units.length - 1);
      bytes /= Math.pow(1024, _pow);
      return bytes.toFixed(precision) + " " + units[_pow];
    },
    async deleteFile(item, index) {
      try {
        if (item.status_done) {
          const { isConfirmed } = await this.showConfirmSwal(
            "Are you sure you want to delete this file?",
            null
          );
          if (isConfirmed) {
            this.addPreloader();
            const result = await PotentialFormAppService.deleteFilesForm({
              id: item.id,
              user_id: this.currentUser.user_id,
            });
            this.$emit("refresh");
            this.removePreloader();
          }
        } else {
          this.items.splice(index, 1);
        }
      } catch (ex) {
        this.removePreloader();
      }
      //
    },
  },
  mounted() {},
};
</script>