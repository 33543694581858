<template>
  <div>
    <b-row>
      <b-col cols="6">
        <validation-provider rules="required" v-slot="{ errors }">
          <b-form-group label="Loan purpose">
            <v-select
              :disabled="disabled"
              :options="optionsLoanPurpose"
              :reduce="(x) => x.id"
              label="name"
              :class="errors[0] && validate ? 'border-danger-select' : ''"
              v-model="form.loan_purpose_id"
            ></v-select>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col>
        <validation-provider
          rules="required|validate-amount"
          v-slot="{ errors }"
        >
          <b-form-group label="Monthly payment">
            <money
              :disabled="disabled"
              class="form-control"
              v-bind="vMoney"
              v-model="form.monthly_payment"
              :class="errors[0] && validate ? 'border-danger' : ''"
            />
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col>
        <validation-provider rules="required|is_numeric" v-slot="{ errors }">
          <b-form-group label="Terms (Months)" :disabled="disabled">
            <b-form-input
              type="number"
              v-model="form.terms"
              :class="errors[0] && validate ? 'border-danger' : ''"
            ></b-form-input>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { VMoney } from "v-money";
export default {
  name: "PersonalLoanForm",
  directives: { money: VMoney },
  props: {
    optionsLoanPurpose: {
      type: Array,
      required: true,
    },
    infoForm: {
      type: Object,
      required: true,
    },
    validate: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      vMoney: {
        decimal: ".",
        thousand: ",",
        prefix: "$ ",
        precision: 2,
        min: 0.0,
      },
      form: {
        id: 0,
        monthly_payment: 0,
        terms: "",
        loan_purpose_id: "",
      },
    };
  },
  watch: {
    form: {
      handler(value) {
        this.$emit("dataForms", {
          plf: value,
        });
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    const { potential_appointment_forms } = this.infoForm;
    if (potential_appointment_forms) {
      this.form = potential_appointment_forms.potential_personal_loan;
    }
  },
};
</script>