var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Employer's name"}},[_c('b-form-input',{class:errors[0] && _vm.validate ? 'border-danger' : '',attrs:{"disabled":_vm.disabled},model:{value:(_vm.form.employers_name),callback:function ($$v) {_vm.$set(_vm.form, "employers_name", $$v)},expression:"form.employers_name"}})],1)]}}])})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Employment status"}},[_c('v-select',{class:errors[0] && _vm.validate ? 'border-danger-select' : '',attrs:{"disabled":_vm.disabled,"options":_vm.questions.employmentStatus,"label":"name","reduce":function (x) { return x.id; }},model:{value:(_vm.form.employment_status_id),callback:function ($$v) {_vm.$set(_vm.form, "employment_status_id", $$v)},expression:"form.employment_status_id"}})],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Time at current work"}},[_c('b-form-input',{class:errors[0] && _vm.validate ? 'border-danger' : '',attrs:{"disabled":_vm.disabled,"type":"number","placeholder":"number of months"},model:{value:(_vm.form.time_at_current_work),callback:function ($$v) {_vm.$set(_vm.form, "time_at_current_work", $$v)},expression:"form.time_at_current_work"}})],1)]}}])})],1),_c('b-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Payment period"}},[_c('v-select',{class:errors[0] && _vm.validate ? 'border-danger-select' : '',attrs:{"disabled":_vm.disabled,"searchable":false,"options":_vm.questions.paymentPeriodo,"reduce":function (x) { return x.value; },"label":"text"},model:{value:(_vm.form.payment_period),callback:function ($$v) {_vm.$set(_vm.form, "payment_period", $$v)},expression:"form.payment_period"}})],1)]}}])})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Occupation"}},[_c('b-input-group',[_c('v-select',{class:errors[0] && _vm.validate ? 'border-danger-select' : '',staticStyle:{"flex":"1"},attrs:{"disabled":_vm.disabled,"options":_vm.questions.occupations,"label":"name","reduce":function (x) { return x.id; }},model:{value:(_vm.form.occupation_id),callback:function ($$v) {_vm.$set(_vm.form, "occupation_id", $$v)},expression:"form.occupation_id"}}),(!_vm.disabled)?_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"info","size":"sm"},on:{"click":function($event){$event.preventDefault();return _vm.addOccupation.apply(null, arguments)}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"20"}})],1)],1):_vm._e()],1)],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"rules":"required|validate-amount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Amount of each period"}},[_c('money',_vm._b({staticClass:"form-control",class:errors[0] && _vm.validate ? 'border-danger' : '',attrs:{"disabled":_vm.disabled},model:{value:(_vm.form.amount_of_each_period),callback:function ($$v) {_vm.$set(_vm.form, "amount_of_each_period", $$v)},expression:"form.amount_of_each_period"}},'money',_vm.vMoney,false))],1)]}}])})],1),_c('b-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"rules":"required|validate-amount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Amount needed"}},[_c('money',_vm._b({staticClass:"form-control",class:errors[0] && _vm.validate ? 'border-danger' : '',attrs:{"disabled":_vm.disabled},model:{value:(_vm.form.amount_needed),callback:function ($$v) {_vm.$set(_vm.form, "amount_needed", $$v)},expression:"form.amount_needed"}},'money',_vm.vMoney,false))],1)]}}])})],1),_c('b-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Paystub","disabled":_vm.disabled}},[_c('b-form-radio-group',{staticStyle:{"width":"100%"},attrs:{"buttons":"","button-variant":errors[0] && _vm.validate ? 'outline-danger' : 'outline-primary',"options":_vm.questions.optionsRadio,"name":"radio-inline"},model:{value:(_vm.form.paystub),callback:function ($$v) {_vm.$set(_vm.form, "paystub", $$v)},expression:"form.paystub"}})],1)]}}])})],1),_c('b-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Bank statements","disabled":_vm.disabled}},[_c('b-form-radio-group',{staticStyle:{"width":"100%"},attrs:{"buttons":"","button-variant":errors[0] && _vm.validate ? 'outline-danger' : 'outline-primary',"options":_vm.questions.optionsRadio,"name":"radio-inline"},model:{value:(_vm.form.bank_statements),callback:function ($$v) {_vm.$set(_vm.form, "bank_statements", $$v)},expression:"form.bank_statements"}})],1)]}}])})],1)],1),_c('b-row',[(_vm.validTypeForm)?_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Colatteral Assets"}},[_c('b-row',{staticClass:"mb-1"},[_c('b-col',{staticClass:"d-flex align-items-center pr-0 justify-content-end",attrs:{"cols":"2"}},[_c('p',{staticClass:"mb-0 text-right marked",staticStyle:{"font-size":"1rem"},attrs:{"for":"auto_marked"}},[_vm._v(" Auto : ")])]),_c('b-col',{attrs:{"cols":"10"}},[_c('b-input-group',{attrs:{"prepend":"Market value"}},[_c('b-form-input',{attrs:{"disabled":_vm.disabled,"id":"auto_marked","type":"number"},model:{value:(_vm.form.colatteral_assets.auto),callback:function ($$v) {_vm.$set(_vm.form.colatteral_assets, "auto", $$v)},expression:"form.colatteral_assets.auto"}})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center pr-0 justify-content-end",attrs:{"cols":"2"}},[_c('p',{staticClass:"mb-0 text-right marked",staticStyle:{"font-size":"1rem"},attrs:{"for":"home_marked"}},[_vm._v(" Home : ")])]),_c('b-col',{attrs:{"cols":"10"}},[_c('b-input-group',{attrs:{"prepend":"Market value"}},[_c('b-form-input',{attrs:{"disabled":_vm.disabled,"type":"number","id":"home_marked"},model:{value:(_vm.form.colatteral_assets.home),callback:function ($$v) {_vm.$set(_vm.form.colatteral_assets, "home", $$v)},expression:"form.colatteral_assets.home"}})],1)],1)],1)],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":_vm.validTypeForm ? 6 : 8}},[_c('b-form-group',{attrs:{"label":"Bank Account Information"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-row',{staticClass:"mb-1"},[_c('b-col',[_c('b-input-group',{attrs:{"prepend":"Routing"}},[_c('b-form-input',{attrs:{"type":"number","disabled":_vm.disabled,"aria-describedby":ariaDescribedby},model:{value:(_vm.form.bank_account_information.routing),callback:function ($$v) {_vm.$set(_vm.form.bank_account_information, "routing", $$v)},expression:"form.bank_account_information.routing"}})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-input-group',{attrs:{"prepend":"Account"}},[_c('b-form-input',{attrs:{"type":"number","disabled":_vm.disabled,"aria-describedby":ariaDescribedby},model:{value:(_vm.form.bank_account_information.account),callback:function ($$v) {_vm.$set(_vm.form.bank_account_information, "account", $$v)},expression:"form.bank_account_information.account"}})],1)],1)],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Prior tax return","disabled":_vm.disabled}},[_c('b-row',{staticClass:"mb-1"},[_c('b-col',{attrs:{"cols":"6"}},[_c('b-input-group',{attrs:{"prepend":"Year"}},[_c('b-form-input',{attrs:{"type":"text","disabled":_vm.disabled,"aria-describedby":_vm.ariaDescribedby},model:{value:(_vm.form.prior_tax_return.year),callback:function ($$v) {_vm.$set(_vm.form.prior_tax_return, "year", $$v)},expression:"form.prior_tax_return.year"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-input-group',{attrs:{"prepend":"Amount"}},[_c('b-form-input',{attrs:{"type":"number","disabled":_vm.disabled,"aria-describedby":_vm.ariaDescribedby},model:{value:(_vm.form.prior_tax_return.amount),callback:function ($$v) {_vm.$set(_vm.form.prior_tax_return, "amount", $$v)},expression:"form.prior_tax_return.amount"}})],1)],1)],1),(!_vm.validTypeForm && (!_vm.statusDone || _vm.dataFiles.length > 0))?_c('b-row',[_c('b-col',[_c('upload-files-form',{attrs:{"statusDone":_vm.statusDone,"dataFiles":_vm.dataFiles},on:{"close":_vm.getItemsFiles,"refresh":function () { return _vm.$emit('refresh'); }}})],1)],1):_vm._e()],1)]}}])})],1),(
        _vm.isNotAMortgageLoan &&
        _vm.validTypeForm &&
        (!_vm.statusDone || _vm.dataFiles.length > 0)
      )?_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label":_vm.titleUploadFile}},[_c('upload-files-form',{attrs:{"statusDone":_vm.statusDone,"dataFiles":_vm.dataFiles},on:{"close":_vm.getItemsFiles,"refresh":function () { return _vm.$emit('refresh'); }}})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }