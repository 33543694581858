var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modalName",attrs:{"modal-class":"modal-primary","title-class":"h3 text-white","title":"Bank Information","size":"lg","hide-footer":_vm.hiddeFooter,"centered":"","no-close-on-backdrop":""},on:{"hidden":function($event){return _vm.closeModal()}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('b-container',[_c('validation-observer',{ref:"formBankInfo"},[_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"BANK NAME"}},[_c('b-form-select',{class:errors[0] && _vm.validate ? 'border-danger' : '',attrs:{"options":_vm.banks,"disabled":!_vm._inSpecialist},model:{value:(_vm.form.bank),callback:function ($$v) {_vm.$set(_vm.form, "bank", $$v)},expression:"form.bank"}}),_c('b-form-invalid-feedback',{attrs:{"state":!(errors[0] && _vm.validate)}},[_vm._v(" select a bank. ")])],1)]}}])})],1),_c('b-col',[_c('validation-provider',{attrs:{"rules":"required|validate-amount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"LOAN AMOUNT"}},[_c('money',_vm._b({staticClass:"form-control",class:errors[0] && _vm.validate ? 'border-danger' : '',attrs:{"disabled":!_vm._inSpecialist},model:{value:(_vm.form.loan_amount),callback:function ($$v) {_vm.$set(_vm.form, "loan_amount", $$v)},expression:"form.loan_amount"}},'money',_vm.vMoney,false)),_c('b-form-invalid-feedback',{attrs:{"state":!(errors[0] && _vm.validate)}},[_vm._v(" enter the loan amount. ")])],1)]}}])})],1),_c('b-col',[_c('validation-provider',{attrs:{"rules":"required|validate-amount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"INTEREST %"}},[_c('money',_vm._b({staticClass:"form-control",class:errors[0] && _vm.validate ? 'border-danger' : '',attrs:{"disabled":!_vm._inSpecialist},model:{value:(_vm.form.interest),callback:function ($$v) {_vm.$set(_vm.form, "interest", $$v)},expression:"form.interest"}},'money',_vm.vPercent,false)),_c('b-form-invalid-feedback',{attrs:{"state":!(errors[0] && _vm.validate)}},[_vm._v(" enter interest. ")])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"rules":"required|is_numeric|min_one"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"TERMS (Months)"}},[_c('b-input',{class:errors[0] && _vm.validate ? 'border-danger' : '',attrs:{"disabled":!_vm._inSpecialist},model:{value:(_vm.form.terms),callback:function ($$v) {_vm.$set(_vm.form, "terms", $$v)},expression:"form.terms"}}),_c('b-form-invalid-feedback',{attrs:{"state":!(errors[0] && _vm.validate)}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',[_c('validation-provider',{attrs:{"rules":"required|validate-amount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"MONTHLY PAYMENT ($)"}},[_c('money',_vm._b({staticClass:"form-control",class:errors[0] && _vm.validate ? 'border-danger' : '',attrs:{"disabled":!_vm._inSpecialist},model:{value:(_vm.form.monthly_payment),callback:function ($$v) {_vm.$set(_vm.form, "monthly_payment", $$v)},expression:"form.monthly_payment"}},'money',_vm.vMoney,false)),_c('b-form-invalid-feedback',{attrs:{"state":!(errors[0] && _vm.validate)}},[_vm._v(" enter monthly payment ")])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"rules":"noHtmlTags"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"COMMENTS (opcional):"}},[_c('b-form-textarea',{class:errors[0] && _vm.validate ? 'border-danger' : '',attrs:{"rows":"5","no-resize":"","disabled":!_vm._inSpecialist},model:{value:(_vm.form.comments),callback:function ($$v) {_vm.$set(_vm.form, "comments", $$v)},expression:"form.comments"}}),_c('b-form-invalid-feedback',{attrs:{"state":!(errors[0] && _vm.validate)}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1)],1)],1)]},proxy:true},{key:"modal-footer",fn:function(){return [_c('b-container',[(_vm._inSpecialist)?[_c('b-button',{staticClass:"float-right mr-1",attrs:{"size":"lg","variant":"success"},on:{"click":function($event){return _vm.saveData(1)}}},[_vm._v(" SAVE ")])]:_vm._e()],2)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }