<template>
  <b-modal
    ref="modalName"
    modal-class="modal-primary"
    title-class="h3 text-white"
    title="ASSIGN APPOINTMENT"
    size="lg"
    @hidden="closeModal()"
    centered
    no-close-on-backdrop
  >
    <b-container fluid>
      <b-row class="mb-2">
        <b-col cols="12" md="4" lg="4">
          <span class="title-tag">TYPE OF APPOINTMENT</span>
          <div class="border border-primary rounded">
            <p
              class="text-primary d-flex align-items-center justify-content-center my-1s"
              style="gap: 5px"
            >
              {{ item.type_description }}
            </p>
          </div>
        </b-col>
        <b-col cols="12" md="4" lg="4">
          <span class="title-tag">DAY</span>
          <div class="border border-primary rounded">
            <p
              class="text-primary d-flex align-items-center justify-content-center my-1s"
              style="gap: 5px"
            >
              {{ item.date_event | myGlobal }}
            </p>
          </div>
        </b-col>
        <b-col cols="12" md="4" lg="4">
          <span class="title-tag">HOUR</span>
          <div class="border border-primary rounded">
            <p
              class="text-primary d-flex align-items-center justify-content-center my-1s"
              style="gap: 5px"
            >
              {{ item.hour_event | myFullTime }}
            </p>
          </div>
        </b-col>
      </b-row>

      <b-table striped hocer small :fields="fields" :items="items">
        <template #cell(pick)="{ item }">
          <div class="text-center ml-1">
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-radio
                v-model="selectSpecialist"
                name="some-radios"
                :value="item"
                :class="ariaDescribedby"
                :disabled="item.is_busy == 1"
              ></b-form-radio>
            </b-form-group>
          </div>
        </template>
        <template #cell(fullname)="{ item }">
          <span>{{ item.fullname }}</span>
          <b-badge class="ml-1" :variant="item.is_busy ? 'warning' : 'success'">{{ item.is_busy ? 'BUSY' : 'AVAILABLE' }}</b-badge>
        </template>
        <template #cell(assigned)="{ item }">
          <b-badge variant="primary">
            {{ item.total_assigned ? item.total_assigned : 0 }}
          </b-badge>
        </template>
      </b-table>
    </b-container>
    <template #modal-footer>
      <b-button
        class="d-flex justify-content-center"
        variant="primary"
        :disabled="selectSpecialist == null"
        @click="assignAppointment()"
      >
        ASSIGN
      </b-button>
    </template>
  </b-modal>
</template>
<script>
// SERVICES
import FinancialApprovalService from "@/views/specialists/sub-modules/financial-approval/services/financial-approval.service.js";

export default {
  props: {
    item: Object,
  },
  data() {
    return {
      items: [],
      fields: [
        {
          key: "pick",
          tdClass: "text-center",
        },
        {
          key: "fullname",
        },
        {
          key: "assigned",
          tdClass: "text-center",
          thClass: "text-center",
          label: "Total assigned appointments",
        },
      ],
      selectSpecialist: null,
    };
  },
  mounted() {
    this.toggleModal("modalName");
  },
  async created() {
    await this.getUsers();
  },
  methods: {
    async getUsers() {
      const { data } = await FinancialApprovalService.getLoanExaminers({
        day_event: this.item.date_event,
        hour_event: this.item.hour_event,
      });
      this.items = data;
    },
    closeModal() {
      this.$emit("close");
    },
    async assignAppointment() {
      try {
        this.addPreloader();
        const params = {
          alert_id: this.item.alert_id,
          user_id: this.selectSpecialist.id,
        };
        const data = await FinancialApprovalService.assignAppointment(params);
        if (data.success) {
          this.showSuccessSwal();
          this.closeModal();
          this.$emit("refresh");
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally { 
        this.removePreloader();
      }
    },
  },
};
</script>