import { render, staticRenderFns } from "./AssignLoanExaminer.vue?vue&type=template&id=edf9877a"
import script from "./AssignLoanExaminer.vue?vue&type=script&lang=js"
export * from "./AssignLoanExaminer.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports